import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import CircularProgress from '@material-ui/core/CircularProgress';
import Modal from '@material-ui/core/Modal';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import DescriptionIcon from '@material-ui/icons/Description';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import EmailIcon from '@material-ui/icons/Email';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RefreshIcon from '@material-ui/icons/Refresh';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import img7 from './../../assets/images/marker/waypoint/flag-green.png';
import img8 from './../../assets/images/marker/waypoint/flag-red.png';

import Config from '../../constants/appConfig';
import MaterialIcon from './../../components/MaterialIcon';
import tools from '../../components/util/tools';
import geo from '../../components/util/geo';
import { notification } from 'antd';
import { useParams } from "react-router";

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
  }
});

class Edit extends React.Component {
  state = {
    id: this.props.id,
    id_equipment: '',
    editData: true,
    isReportReady: false,
    openModal: false,
    jobReportId: null,
    job: {},
    jobReport: {},
    jobProperty: [],
    contractor: '',
    cpf_cnpj: '',
    property: '',
    city: '',
    address: '',
    uf: '',
    culture: '',
    area: '',
    volume: '',
    startDate: '',
    endDate: '',
    time_voo: '',
    application_period: '',
    type_service: {
      insecticide: false,
      herbicide: false,
      fungicide: false,
      seeding: false,
      fertilizing: false,
    },
    temperature: '',
    humidity: '',
    wind_speed: '',
    flight_height: '',
    selectedService: '',
    applicationRange: '',
    plane: '',
    pilot: '',
    canac: '',
    equipment: {
      type: '',
      name: '',
      details: {
        core: '',
        disco: '',
        angulo: '',
        ponta: '',
        anguloPa: '',
        malha: '',
        vru: '',
        orificio: '',
      }
    },
    product: [],
    technicians: [],
    executor: '',
    executor_crea_cpf: '',
    agronomists: [],
    agricultural_engineer: '',
    engineer_crea_cpf: '',
    longitude: '',
    latitude: '',
    flight_direction: [],
    wind_direction: '',
    runway_latitude: '',
    runway_longitude: '',
    id_client: '',
    id_pilot: '',
    id_property: '',
    id_technician: '',
    id_agronomist: '',
    jobReportIdjob: '',
    cultureData: [],
    list_all_prod: [],
    filteredProducts: [],
    currentPage: 1,
    itemsPerPage: 21,
    applicationConditions: {
      data: '',
      temperaturaMatutino: false,
      temperaturaVespertino: false,
      temperaturaInicialMatutino: '',
      temperaturaFinalMatutino: '',
      temperaturaInicialVespertino: '',
      temperaturaFinalVespertino: '',
      umidadeMatutino: false,
      umidadeVespertino: false,
      umidadeInicialMatutino: '',
      umidadeFinalMatutino: '',
      umidadeInicialVespertino: '',
      umidadeFinalVespertino: '',
      velocidadeMatutino: false,
      velocidadeVespertino: false,
      velocidadeInicialMatutino: '',
      velocidadeFinalMatutino: '',
      velocidadeInicialVespertino: '',
      velocidadeFinalVespertino: '',
      horarioMatutino: false,
      horarioVespertino: false,
      horarioInicialMatutino: '',
      horarioFinalMatutino: '',
      horarioInicialVespertino: '',
      horarioFinalVespertino: '',
    },
    prescriptionDate: new Date().toISOString().split('T')[0],
    prescriptionNumber: '',
    registrationNumber: '',
    polygon: '',
    title: '',
    areaData: '',
    contractorEmail: '',
    pilotEmail: '',
    engineerEmail: '',
    executorEmail: '',
    fileSigned: '',
    documentGuid: '',
    documentId: '',
    documentTitle: '',
    documentData: {},
    reportGenerated: false,
    createDocument: '',
  };

  componentDidMount() {
    this.getProd().then(() => {
      const selectedProductIds = this.state.product.map(p => p.id);
      const sortedProducts = this.state.list_all_prod
        .sort((a, b) => {
          const aSelected = selectedProductIds.includes(a.id);
          const bSelected = selectedProductIds.includes(b.id);
          return aSelected === bSelected ? 0 : aSelected ? -1 : 1;
        });

      this.setState({
        filteredProducts: sortedProducts
      });
      this.readJob();
      this.readReports();
    });
    this.readTechnician();
    this.readAgronomist();
    this.loadCultures();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.jobReportId && !prevState.jobReportId) {
      this.setState({ editData: false, isReportReady: true });
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  };

  readJob = () => {
    const apiUrl = `${Config.server}/job_detail/${this.state.id}`;

    const options = {
      method: 'GET',
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    };

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then((result) => {
        const jobDetail = result;

        const equipment = jobDetail.planejamento?.planningEquipment ? JSON.parse(jobDetail.planejamento.planningEquipment) : [];
        const temperature = jobDetail.planejamento?.planningTempMin ? JSON.parse(jobDetail.planejamento.planningTempMin) : [];
        const humidity = jobDetail.planejamento?.planningHumidityMax ? JSON.parse(jobDetail.planejamento.planningHumidityMax) : [];
        const culture = jobDetail.planejamento?.planningAgricultureCulture ? JSON.parse(jobDetail.planejamento.planningAgricultureCulture) : [];
        const windSpeed = jobDetail.planejamento?.planningWindSpeed ? JSON.parse(jobDetail.planejamento.planningWindSpeed) : [];
        const applicationPeriod = jobDetail.planejamento?.planningApplication_period ? JSON.parse(jobDetail.planejamento.planningApplication_period) : [];
        const selectedService = jobDetail.planejamento?.planningSelectedService ? JSON.parse(jobDetail.planejamento.planningSelectedService) : [];

        const serviceMapping = {
          Inseticida: 'insecticide',
          Herbicida: 'herbicide',
          Fungicida: 'fungicide',
          Semeadura: 'seeding',
          Adubação: 'fertilizing'
        };
        const typeService = jobDetail.planejamento?.planningTypeService ? JSON.parse(jobDetail.planejamento.planningTypeService) : [];

        let updatedTypeService = { ...this.state.type_service };
        typeService.forEach(service => {
          const key = serviceMapping[service];
          if (key) {
            updatedTypeService[key] = true;
          }
        });

        const productArray = jobDetail.planejamento?.planningProduct ? JSON.parse(jobDetail.planejamento.planningProduct) : [];
        const products = JSON.parse(productArray[0] || '{}');
        let updatedProducts = [];

        for (const [name, details] of Object.entries(products)) {
          const productItem = this.state.list_all_prod.find(prod => prod.name.trim().toLowerCase() === name.trim().toLowerCase());
          if (productItem) {
            updatedProducts.push({
              ...productItem,
              dosage: details.dosage,
              applicationRate: details.total.toFixed(2),
            });
          }
        }

        const coordinateLatitude = jobDetail.execucao ? jobDetail.execucao.latitude :
          jobDetail.planejamento?.latitude;
        const coordinateLongitude = jobDetail.execucao ? jobDetail.execucao.longitude :
          jobDetail.planejamento?.longitude;

        const lat_refuel = jobDetail.execucao ? jobDetail.execucao.lng_refuel :
          jobDetail.planejamento?.lng_refuel;
        const lng_refuel = jobDetail.execucao ? jobDetail.execucao.lng_refuel :
          jobDetail.planejamento?.lng_refuel;

        let jobProperty = [...this.state.jobProperty];
        let tempProperty = {
          id_property: '',
          property_name: ''
        }
        if (jobDetail.planejamento) {
          for (let i = 0; i < jobDetail.planejamento.area.length; i++) {
            const item = jobDetail.planejamento.area[i];
            if (item.property_name) {
              tempProperty.id_property = item.id_property;
              tempProperty.property_name = item.property_name;
              break;
            }
          };
          if (!jobProperty.some(a => tempProperty.property_name === a.property_name)) {
            jobProperty.push({ property_id: tempProperty.id_property, property_name: tempProperty.property_name });
          }
        }
        const selectedProperty = tempProperty.id_property ? tempProperty.property_name : 'Outro';

        const validData = (dataArray) => {
          for (let data of dataArray) {
            if (data && data.trim() !== '') {
              return data;
            }
          }
          return null;
        }
        const validCulture = validData(culture);
        const validTemperature = validData(temperature);
        const validHumidity = validData(humidity);

        const secondsToTimeFormat = (seconds) => {
          const date = new Date(0);
          date.setSeconds(seconds);
          return date.toISOString().substr(11, 8);
        }

        const startDate = jobDetail.execucao && jobDetail.execucao.startDate
          ? Moment(jobDetail.execucao.startDate).format("YYYY-MM-DDTHH:mm")
          : (jobDetail.planejamento && jobDetail.planejamento.startDate
            ? Moment(jobDetail.planejamento.startDate).format("YYYY-MM-DDTHH:mm")
            : '');

        const endDate = jobDetail.execucao && jobDetail.execucao.endDate
          ? Moment(jobDetail.execucao.endDate).format("YYYY-MM-DDTHH:mm")
          : (jobDetail.planejamento && jobDetail.planejamento.endDate
            ? Moment(jobDetail.planejamento.endDate).format("YYYY-MM-DDTHH:mm")
            : '');

        const uniquePolygons = jobDetail.planejamento && jobDetail.planejamento.area.length > 0 ? jobDetail.planejamento.area.map(item => item.points) :
          jobDetail.execucao ? jobDetail.execucao.area.map(item => item.points) : [];

        const formatABs = (ABs) => {
          if (!ABs) return [];
          const points = JSON.parse(ABs);
          const angle = this.getAngleFromCoordinates(points[0][1], points[0][0], points[1][1], points[1][0]);
          const direction = this.getCardinalDirection(angle);

          return {
            startPoint: { lat: points[0][1], lng: points[0][0] },
            endPoint: { lat: points[1][1], lng: points[1][0] },
            direction: direction
          };
        }
        let formattedABs = jobDetail.planejamento && jobDetail.planejamento.area.length > 0 ? jobDetail.planejamento.area.map(item => formatABs(item.ABs)) :
          jobDetail.execucao ? jobDetail.execucao.area.map(item => formatABs(item.ABs)) : [];

        const flightDirections = formattedABs.map(ab => ab.direction);

        let applicationRange = '';
        if (jobDetail.execucao?.area.length > 0) {
          let temp = jobDetail.execucao.area.sort(function (a, b) { return b.spray - a.spray })[0].applicationRange;
          applicationRange = temp ? temp : '';
        } else if (jobDetail.planejamento?.area.length > 0) {
          let temp = jobDetail.planejamento.area.sort(function (a, b) { return b.spray - a.spray })[0].applicationRange;
          applicationRange = temp ? temp : '';
        }
        let flightHeight = '';
        if (jobDetail.execucao?.area.length > 0) {
          let temp = jobDetail.execucao.area.sort(function (a, b) { return b.spray - a.spray })[0].flightHeight
          flightHeight = temp ? temp : '';
        } else if (jobDetail.planejamento?.area.length > 0) {
          let temp = jobDetail.planejamento.area.sort(function (a, b) { return b.spray - a.spray })[0].flightHeight
          flightHeight = temp ? temp : '';
        }

        let id_property = '';
        if (jobDetail.planejamento?.area?.length > 0) {
          let tempPlanejamento = jobDetail.planejamento.area.filter(a => !!a.id_property);
          if (tempPlanejamento.length > 0) {
            id_property = tempPlanejamento.sort((a, b) => { return b.spray - a.spray })[0].id_property;
          }
        }

        if (!id_property && jobDetail.execucao?.area?.length > 0) {
          let tempExecucao = jobDetail.execucao.area.filter(a => !!a.id_property);
          if (tempExecucao.length > 0) {
            id_property = tempExecucao.sort((a, b) => { return b.spray - a.spray })[0].id_property;
          }
        }

        const windDirection = jobDetail.planejamento?.planningWindDirection ? JSON.parse(jobDetail.planejamento.planningWindDirection) : [];

        this.setState({
          job: jobDetail,
          id_client: jobDetail.execucao?.id_client || jobDetail.planejamento?.id_client || '',
          id_pilot: jobDetail.execucao?.id_pilot || jobDetail.planejamento?.id_pilot || '',
          id_property: id_property,
          id_equipment: jobDetail.execucao?.id_equipment || jobDetail.planejamento?.id_equipment || '',
          contractor: jobDetail.execucao?.client_name || jobDetail.planejamento?.client_name || '',
          cpf_cnpj: jobDetail.execucao?.client_cpf_cnpj || jobDetail.planejamento?.client_cpf_cnpj || '',
          contractorEmail: jobDetail.planejamento?.client_email || '',
          area: jobDetail.execucao?.spray || jobDetail.planejamento?.spray || '',
          volume: jobDetail.execucao?.liters || jobDetail.planejamento?.liters || '',
          startDate: startDate || '',
          endDate: endDate || '',
          time_voo: jobDetail.execucao?.time_total ? secondsToTimeFormat(jobDetail.execucao.time_total)
            : jobDetail.planejamento?.time_total ? secondsToTimeFormat(jobDetail.planejamento.time_total)
              : '',
          plane: jobDetail.execucao?.plane || jobDetail.planejamento?.plane || '',
          pilot: jobDetail.planejamento?.pilot_name || '',
          canac: jobDetail.planejamento?.pilot_canac || '',
          pilotEmail: jobDetail.planejamento?.pilot_email || '',
          temperature: validTemperature || '',
          humidity: validHumidity || '',
          culture: validCulture || '',
          type_service: updatedTypeService,
          equipment: {
            name: equipment || '',
          },
          flight_height: flightHeight || '',
          applicationRange: applicationRange || '',
          product: updatedProducts || [],
          filteredProducts: this.state.list_all_prod || [],
          title: jobDetail.execucao?.title || jobDetail.planejamento?.title || '',
          latitude: coordinateLatitude ? geo.toDMS(coordinateLatitude, 'latitude', null, this.props.res) : '',
          longitude: coordinateLongitude ? geo.toDMS(coordinateLongitude, 'longitude', null, this.props.res) : '',
          runway_latitude: lat_refuel ? geo.toDMS(lat_refuel, 'latitude', null, this.props.res) : '',
          runway_longitude: lng_refuel ? geo.toDMS(lng_refuel, 'longitude', null, this.props.res) : '',
          polygon: uniquePolygons || [],
          property: selectedProperty || '',
          jobProperty: jobProperty || [],
          flight_direction: flightDirections || [],
          areaData: formattedABs || [],
          applicationConditions: {
            ...this.state.applicationConditions,
            data: Moment(startDate).format("YYYY-MM-DD") || '',
          },
          wind_speed: windSpeed || '',
          wind_direction: windDirection.length > 0 ? windDirection[0] : '',
          application_period: applicationPeriod[0] || '',
          selectedService: selectedService[0] || '',
          registrationNumber: jobDetail.execucao?.registrationNumber || jobDetail.planejamento?.registrationNumber || '',
        }, () => {
          if (equipment.length > 0) {
            this.readEquipments();
          }
          if (id_property) {
            this.readProperty(id_property);
          }
          this.drawPolygon();
        });
      })
      .catch((error) => {
        if (error.status === 405) {
          notification.open({
            message: this.props.res.PERMISSAO,
            description: this.props.res.PERMISSAO_MSG,
            icon: <MaterialIcon icon="error" className="text-danger" />
          });
        } else if (error.status === 401 || error.status === 403) {
          this.props.link('/login');
        }
      });
  };

  getAngleFromCoordinates = (lat1, lng1, lat2, lng2) => {
    const rad = Math.PI / 180;
    const dLon = (lng2 - lng1) * rad;
    const y = Math.sin(dLon) * Math.cos(lat2 * rad);
    const x = Math.cos(lat1 * rad) * Math.sin(lat2 * rad) - Math.sin(lat1 * rad) * Math.cos(lat2 * rad) * Math.cos(dLon);
    let brng = Math.atan2(y, x);
    brng = brng / rad;
    brng = (brng + 360) % 360;
    return brng;
  }

  getCardinalDirection = (angle) => {
    if (angle >= 348.75 || angle < 11.25) {
      return 'N';
    } else if (angle >= 11.25 && angle < 33.75) {
      return 'NNE';
    } else if (angle >= 33.75 && angle < 56.25) {
      return 'NE';
    } else if (angle >= 56.25 && angle < 78.75) {
      return 'ENE';
    } else if (angle >= 78.75 && angle < 101.25) {
      return 'L';
    } else if (angle >= 101.25 && angle < 123.75) {
      return 'ESE';
    } else if (angle >= 123.75 && angle < 146.25) {
      return 'SE';
    } else if (angle >= 146.25 && angle < 168.75) {
      return 'SSE';
    } else if (angle >= 168.75 && angle < 191.25) {
      return 'S';
    } else if (angle >= 191.25 && angle < 213.75) {
      return 'SSO';
    } else if (angle >= 213.75 && angle < 236.25) {
      return 'SO';
    } else if (angle >= 236.25 && angle < 258.75) {
      return 'OSO';
    } else if (angle >= 258.75 && angle < 281.25) {
      return 'O';
    } else if (angle >= 281.25 && angle < 303.75) {
      return 'ONO';
    } else if (angle >= 303.75 && angle < 326.25) {
      return 'NO';
    } else if (angle >= 326.25 && angle < 348.75) {
      return 'NNO';
    }
  }

  readEquipments = () => {
    const apiUrl = `${Config.server}/plane_equipment/${this.state.id_equipment}`;

    const options = {
      method: 'GET',
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    };

    const equipmentTypeMap = {
      'Bico Hidráulico Core e Disco': 'bicoHidraulicoCoreDisco',
      'Bico Hidráulico Cerâmica': 'bicoHidraulicoCeramica',
      'Bico Hidráulico Jato Sólido': 'bicoHidraulicoSolido',
      'Bico Hidráulico Jato Plano': 'bicoHidraulicoPlano',
      'Atomizador Rotativo Tela': 'atomizadorTela',
      'Atomizador Rotativo Disco': 'atomizadorDisco',
      'CP03/TT03': 'cp03tt03',
      'CP09/TT09': 'cp09tt09',
      'CP11/TT11': 'cp11tt11'
    };

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then((result) => {
        const equipment = result[0] || {};
        const equipType = equipmentTypeMap[equipment.equipmentTypeName];

        this.setState({
          equipment: {
            type: equipType,
            name: equipment.equipment || '',
            details: {
              angulo: equipment.deflectionAngle || '',
              ponta: equipment.jetType || '',
              anguloPa: equipment.angleBlades || '',
              malha: equipment.screen || '',
              vru: equipment.flowRegulator || '',
              orificio: equipment.jetType || '',
              core: equipment.core || '',
              disco: equipment.disco || '',
            }
          }
        });
      })
      .catch((error) => {
        if (error.status === 405) {
          notification.open({
            message: this.props.res.PERMISSAO,
            description: this.props.res.PERMISSAO_MSG,
            icon: <MaterialIcon icon="error" className="text-danger" />
          });
        } else if (error.status === 401 || error.status === 403) {
          this.props.link('/login');
        }
      });
  };

  readTechnician = () => {
    const apiUrl = `${Config.server}/technician`;

    const options = {
      method: 'GET',
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    };

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then((result) => {
        if (result.length > 0) {
          const firstTechnician = result[0];
          this.setState({
            technicians: result,
            id_technician: firstTechnician.id,
            executor: firstTechnician.name,
            executor_crea_cpf: firstTechnician.cfta,
            executorEmail: firstTechnician.email,
          });
        }
      })
      .catch((error) => {
        if (error.status === 405) {
          notification.open({
            message: this.props.res.PERMISSAO,
            description: this.props.res.PERMISSAO_MSG,
            icon: <MaterialIcon icon="error" className="text-danger" />
          });
        } else if (error.status === 401 || error.status === 403) {
          this.props.link('/login');
        }
      });
  };

  handleTechnicianChange = (event) => {
    const selectedTechnician = this.state.technicians.find(technician => technician.name === event.target.value);
    this.setState({
      executor: selectedTechnician.name,
      executor_crea_cpf: selectedTechnician.crea,
      executorEmail: selectedTechnician.email,
    });
  };

  readAgronomist = () => {
    const apiUrl = `${Config.server}/agronomist`;

    const options = {
      method: 'GET',
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    };

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then((result) => {
        if (result.length > 0) {
          const firstAgronomist = result[0];

          this.setState({
            agronomists: result,
            id_agronomist: firstAgronomist.id,
            agricultural_engineer: firstAgronomist.name,
            engineer_crea_cpf: firstAgronomist.crea,
            engineerEmail: firstAgronomist.email
          });
        }
      })
      .catch((error) => {
        if (error.status === 405) {
          notification.open({
            message: this.props.res.PERMISSAO,
            description: this.props.res.PERMISSAO_MSG,
            icon: <MaterialIcon icon="error" className="text-danger" />
          });
        } else if (error.status === 401 || error.status === 403) {
          this.props.link('/login');
        }
      });
  };

  handleAgronomistChange = (event) => {
    const selectedAgronomist = this.state.agronomists.find(agronomist => agronomist.name === event.target.value);
    this.setState({
      agricultural_engineer: selectedAgronomist.name,
      engineer_crea_cpf: selectedAgronomist.crea,
      engineerEmail: selectedAgronomist.email,
    });
  };

  handleChangeProperty = (event) => {
    const selectedProperty = event.target.value;

    if (selectedProperty === 'Outro') {
      this.setState({
        property: selectedProperty,
        city: '',
        address: '',
        uf: ''
      });
    } else {
      const propertyDetails = this.state.jobProperty.find(prop => prop.property_name === selectedProperty) || {};
      this.setState({
        id_property: propertyDetails.property_id || '',
        property: selectedProperty
      }, () => {
        this.readProperty(propertyDetails.property_id);
      });
    }
  };

  isFormValid = () => {
    return this.state.contractorEmail && this.state.pilotEmail && this.state.executorEmail && this.state.engineerEmail;
  };

  handleSubmit = (event) => {
    event.preventDefault();

    if (!this.isFormValid()) {
      notification.open({
        message: this.props.res.ATENCAO,
        description: this.props.res.PREENCHA_EMAILS,
        icon: <MaterialIcon icon="error" className="text-danger" />
      });
      return;
    }

    let apiUrl = '';
    let method = '';
    apiUrl = Config.server + '/reports_mapa';

    const formData = new URLSearchParams();
    if (this.state.jobReportId) {
      apiUrl += `/${this.state.jobReportId}`;
      formData.append('id', this.state.jobReportId);
      method = 'PUT';
    } else {
      method = 'POST';
    }

    const appendFormData = (key, value) => {
      if (value === null || value === undefined) {
        formData.append(key, '');
      } else {
        formData.append(key, value);
      }
    };

    appendFormData('id_client', this.state.id_client);
    appendFormData('id_job', this.state.id);
    appendFormData('id_pilot', this.state.id_pilot);
    appendFormData('id_property', this.state.id_property);
    appendFormData('contractor', this.state.contractor);
    appendFormData('cpf_cnpj', this.state.cpf_cnpj);
    appendFormData('contractorEmail', this.state.contractorEmail);
    appendFormData('property', this.state.property);
    appendFormData('city', this.state.city);
    appendFormData('address', this.state.address);
    appendFormData('uf', this.state.uf);
    appendFormData('culture', this.state.culture);
    appendFormData('area', this.state.area);
    appendFormData('volume', this.state.volume);
    appendFormData('initialDate', this.state.startDate);
    appendFormData('finalDate', this.state.endDate);
    appendFormData('time_voo', this.state.time_voo);
    appendFormData('application_period', this.state.application_period);
    appendFormData('type_service', JSON.stringify(this.state.type_service));
    appendFormData('temperature', this.state.temperature);
    appendFormData('humidity', this.state.humidity);
    appendFormData('wind_speed', this.state.wind_speed);
    appendFormData('service', this.state.selectedService);
    appendFormData('flight_height', this.state.flight_height);
    appendFormData('applicationRange', this.state.applicationRange);
    appendFormData('plane', this.state.plane);
    appendFormData('pilot', this.state.pilot);
    appendFormData('canac', this.state.canac);
    appendFormData('pilotEmail', this.state.pilotEmail);
    appendFormData('equipment', JSON.stringify(this.state.equipment));
    appendFormData('product', JSON.stringify(this.state.product));
    appendFormData('executor', this.state.executor);
    appendFormData('executor_crea_cpf', this.state.executor_crea_cpf);
    appendFormData('executorEmail', this.state.executorEmail);
    appendFormData('agricultural_engineer', this.state.agricultural_engineer);
    appendFormData('engineer_crea_cpf', this.state.engineer_crea_cpf);
    appendFormData('engineerEmail', this.state.engineerEmail);
    appendFormData('longitude', this.state.longitude);
    appendFormData('latitude', this.state.latitude);
    appendFormData('flight_direction', JSON.stringify(this.state.flight_direction));
    appendFormData('areaData', JSON.stringify(this.state.areaData));
    appendFormData('wind_direction', this.state.wind_direction);
    appendFormData('runway_longitude', this.state.runway_longitude);
    appendFormData('runway_latitude', this.state.runway_latitude);
    appendFormData('applicationConditions', JSON.stringify(this.state.applicationConditions));
    appendFormData('prescriptionDate', this.state.prescriptionDate);
    appendFormData('prescriptionNumber', this.state.prescriptionNumber);
    appendFormData('registrationNumber', this.state.registrationNumber);
    appendFormData('polygon', JSON.stringify(this.state.polygon));
    appendFormData('title', this.state.title);

    let options = {
      method: method,
      body: formData,
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {
          if (result && result.error) {
            notification.open({
              message: this.props.res[result.message],
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else {
            let msg = this.props.res.ITEM_INCLUIR;
            if (this.state.id) {
              msg = this.props.res.ITEM_ALTERADO;
            }
            notification.open({
              message: this.props.res.SUCESSO,
              description: msg,
              icon: <MaterialIcon icon="check_circle" className="text-success" />
            });
          }
          this.setState({
            isReportReady: true,
            editData: false,
            jobReportIdjob: result,
          });

          this.readReport(this.state.id);
        },
        (error) => {
          if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            this.props.link('/login');
          }
          else {
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG_INFORMACOES,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  }

  createDocument = (event) => {
    event && event.preventDefault();
    let apiUrl = '';
    let method = '';
    apiUrl = Config.server + '/document';

    const formData = new URLSearchParams();
    const convertDateToMySQLFormat = (date) => {
      const d = new Date(date);
      const formattedDate = d.toISOString().slice(0, 19).replace('T', ' ');
      return formattedDate;
    };
    const currentDate = convertDateToMySQLFormat(new Date());

    if (this.state.documentId) {
      apiUrl += `/${this.state.documentId}`;
      formData.append('id', this.state.documentId);
      method = 'PUT';
      formData.append('creationDate', this.state.createDocument);
    } else {
      method = 'POST';
      formData.append('creationDate', currentDate);
    }

    formData.append('id_reports_mapa', this.state.jobReportId);
    formData.append('name', this.state.property !== 'Outro' ? this.state.property : this.state.contractor);
    formData.append('title', this.state.title);
    formData.append('updateDate', currentDate);
    formData.append('fileGuid', this.state.documentGuid);
    formData.append('fileSigned', this.state.fileSigned);

    const options = {
      method: method,
      body: formData,
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    };

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        return res.json();
      })
      .then((result) => {
        notification.open({
          message: this.props.res.SUCESSO,
          description: this.props.res.DOCUMENTO_GERADO,
          icon: <MaterialIcon icon="check_circle" className="text-success" />
        });

        this.readDocument();
      })
      .catch((error) => {
        notification.open({
          message: this.props.res.ERRO,
          description: this.props.res.ERRO_MSG,
          icon: <MaterialIcon icon="error" className="text-danger" />
        });
      });
  };

  readDocument = () => {
    const apiUrl = `${Config.server}/document/${this.state.jobReportId}`;

    const options = {
      method: 'GET',
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    };

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        return res.json();
      })
      .then((result) => {
        const convertDateToMySQLFormat = (date) => {
          const d = new Date(date);
          const year = d.getFullYear();
          const month = ('0' + (d.getMonth() + 1)).slice(-2);
          const day = ('0' + d.getDate()).slice(-2);
          const hours = ('0' + d.getHours()).slice(-2);
          const minutes = ('0' + d.getMinutes()).slice(-2);
          const seconds = ('0' + d.getSeconds()).slice(-2);
          return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        };
        const creationDate = convertDateToMySQLFormat(result.creationDate);

        this.setState({
          documentData: result.autentiqueData,
          documentGuid: result.fileGuid,
          documentId: result.id,
          fileSigned: result.autentiqueData.arquivo.assinado,
          createDocument: creationDate
        }, () => {
          this.createSignatures();
        });
      })
      .catch((error) => {
        if (error.status === 405) {
          notification.open({
            message: this.props.res.PERMISSAO,
            description: this.props.res.PERMISSAO_MSG,
            icon: <MaterialIcon icon="error" className="text-danger" />
          });
        } else if (error.status === 401 || error.status === 403) {
          this.props.link('/login');
        }
      });
  };

  createSignatures = () => {
    const partes = this.state.documentData.partes || [];

    this.readSignatures()
      .then(existingSignatures => {
        partes.forEach(parte => {
          const formData = new URLSearchParams();
          formData.append('id_document', this.state.documentId);
          formData.append('name', parte.nome);
          formData.append('email', parte.email);

          const statusDetails = this.getStatusDetails(parte);
          formData.append('updateDate', statusDetails.date);
          formData.append('status', statusDetails.etapa);

          formData.append('id_agronomist', parte.email === this.state.engineerEmail ? (this.state.id_agronomist || '') : '');
          formData.append('id_pilot', parte.email === this.state.pilotEmail ? (this.state.id_pilot || '') : '');
          formData.append('id_technician', parte.email === this.state.executorEmail ? (this.state.id_technician || '') : '');
          formData.append('id_client', parte.email === this.state.contractorEmail ? (this.state.id_client || '') : '');

          let existingSignature = existingSignatures.find(sig => sig.email === parte.email);

          let apiUrl = `${Config.server}/signature`;
          let method = 'POST';

          if (existingSignature) {
            apiUrl += `/${existingSignature.id}`;
            method = 'PUT';
          }

          const options = {
            method: method,
            headers: {
              'Authorization': localStorage.getItem('access-token-jwt'),
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: formData.toString()
          };

          fetch(apiUrl, options)
            .then((res) => {
              if (res.status !== 201 && res.status !== 200) {
                return res.json().then(data => {
                  throw new Error(data.message || 'Unexpected error');
                });
              }
              return res.json();
            })
            .then(result => {
            })
            .catch(error => {
            });
        });

        this.readSignatures();
      })
      .catch(error => {
        console.error('Error reading signatures:', error);
      });
  };

  readSignatures = () => {
    const apiUrl = `${Config.server}/signature/${this.state.documentId}`;

    const options = {
      method: 'GET',
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    };

    return fetch(apiUrl, options)
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else if (res.status === 404) {
          return [];
        } else {
          return res.json().then(data => {
            throw new Error(data.message || 'Unexpected error');
          });
        }
      })
      .catch(error => {
        throw error;
      });
  };

  readReports = () => {
    const apiUrl = `${Config.server}/reports_mapa`;

    const options = {
      method: 'GET',
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    };

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then((result) => {
        const filterJobReport = result.filter(res => res.id_job == this.state.id);
        if (filterJobReport.length > 0) {
          this.setState({ jobReportId: filterJobReport[0].id, jobReportIdjob: filterJobReport[0].id_job });
          this.readReport(filterJobReport[0].id_job);
        }
      })
      .catch((error) => {
        if (error.status === 405) {
          notification.open({
            message: this.props.res.PERMISSAO,
            description: this.props.res.PERMISSAO_MSG,
            icon: <MaterialIcon icon="error" className="text-danger" />
          });
        } else if (error.status === 401 || error.status === 403) {
          this.props.link('/login');
        }
      });
  };

  readProperty = (idProperty) => {
    const apiUrl = `${Config.server}/property/${idProperty}`;

    const options = {
      method: 'GET',
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    };

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then((result) => {
        const propertyDetails = result[0] || {};
        let jobProperty = [...this.state.jobProperty];
        if (!jobProperty.some(p => p.property_name === propertyDetails.name)) {
          jobProperty.push({ property_id: propertyDetails.id, property_name: propertyDetails.name });
        }

        const addressParts = [];
        if (propertyDetails.logradouro) addressParts.push(propertyDetails.logradouro);
        if (propertyDetails.numero) addressParts.push(propertyDetails.numero);
        const address = addressParts.join(', ');

        this.setState({
          jobProperty: jobProperty,
          property: propertyDetails.name,
          city: propertyDetails.localidade || '',
          address: address || '',
          uf: propertyDetails.uf || '',
        });
      })
      .catch((error) => {
        if (error.status === 405) {
          notification.open({
            message: this.props.res.PERMISSAO,
            description: this.props.res.PERMISSAO_MSG,
            icon: <MaterialIcon icon="error" className="text-danger" />
          });
        } else if (error.status === 401 || error.status === 403) {
          this.props.link('/login');
        }
      });
  };

  readReport = (id) => {
    const apiUrl = `${Config.server}/reports_mapa/${id}`;

    const options = {
      method: 'GET',
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    };

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then((result) => {
        const formatDateForInput = (dateString) => {
          const date = new Date(dateString);
          const year = date.getFullYear();
          const month = (`0${date.getMonth() + 1}`).slice(-2);
          const day = (`0${date.getDate()}`).slice(-2);
          const hours = (`0${date.getHours()}`).slice(-2);
          const minutes = (`0${date.getMinutes()}`).slice(-2);

          return `${year}-${month}-${day}T${hours}:${minutes}`;
        };

        const formattedInitialDate = formatDateForInput(result[0].initialDate);
        const formattedFinalDate = formatDateForInput(result[0].finalDate);
        this.setState({
          jobReportId: result[0].id,
          jobReport: result[0],
          id_client: result[0].id_client,
          id_pilot: result[0].id_pilot,
          id_property: result[0].id_property,
          contractor: result[0].contractor,
          cpf_cnpj: result[0].cpf_cnpj,
          contractorEmail: result[0].contractorEmail,
          property: result[0].property,
          city: result[0].city,
          address: result[0].address,
          uf: result[0].uf,
          culture: result[0].culture,
          area: result[0].area,
          volume: result[0].volume,
          time_voo: result[0].time_voo,
          startDate: formattedInitialDate,
          endDate: formattedFinalDate,
          application_period: result[0].application_period,
          type_service: result[0].type_service ? JSON.parse(result[0].type_service) : {
            insecticide: false,
            herbicide: false,
            fungicide: false,
            seeding: false,
            fertilizing: false
          },
          temperature: result[0].temperature,
          humidity: result[0].humidity,
          wind_speed: result[0].wind_speed,
          selectedService: result[0].service,
          flight_height: result[0].flight_height,
          applicationRange: result[0].applicationRange,
          plane: result[0].plane,
          pilot: result[0].pilot,
          canac: result[0].canac,
          pilotEmail: result[0].pilotEmail,
          equipment: JSON.parse(result[0].equipment),
          product: JSON.parse(result[0].product),
          executor: result[0].executor,
          executor_crea_cpf: result[0].executor_crea_cpf,
          executorEmail: result[0].executorEmail,
          agricultural_engineer: result[0].agricultural_engineer,
          engineer_crea_cpf: result[0].engineer_crea_cpf,
          engineerEmail: result[0].engineerEmail,
          longitude: result[0].longitude,
          latitude: result[0].latitude,
          flight_direction: JSON.parse(result[0].flight_direction),
          wind_direction: result[0].wind_direction,
          runway_longitude: result[0].runway_longitude,
          runway_latitude: result[0].runway_latitude,
          applicationConditions: JSON.parse(result[0].applicationConditions),
          prescriptionDate: result[0].prescriptionDate,
          prescriptionNumber: result[0].prescriptionNumber,
          registrationNumber: result[0].registrationNumber,
          polygon: JSON.parse(result[0].polygon),
        });

        this.readDocument();
      })
      .catch((error) => {
        if (error.status === 405) {
          notification.open({
            message: this.props.res.PERMISSAO,
            description: this.props.res.PERMISSAO_MSG,
            icon: <MaterialIcon icon="error" className="text-danger" />
          });
        } else if (error.status === 401 || error.status === 403) {
          this.props.link('/login');
        } else {
          notification.open({
            message: this.props.res.ERRO,
            description: this.props.res.ERRO_MSG,
            icon: <MaterialIcon icon="error" className="text-danger" />
          });
        }
      });
  };

  handleChange = (name) => (event) => {
    const { value } = event.target;
    if (name === 'equipment') {
      this.setState(prevState => ({
        equipment: {
          ...prevState.equipment,
          type: value,
        }
      }));
    } else if (name === 'name') {
      this.setState(prevState => ({
        equipment: {
          ...prevState.equipment,
          name: value,
        }
      }));
    } else {
      this.setState({ [name]: value });
    }
  };

  handleDirectionChange = (event) => {
    this.setState({ flight_direction: event.target.value });
  };

  handleChangeCoordinates = (name) => (event) => {
    let value = event.target.value;
    this.setState({
      [name]: value,
    });
  };

  handleBlurCoordinates = (name) => (event) => {
    let value = this.state[name];
    let { formattedInput, isValid } = this.maskCoordinateInput(value, name.includes('latitude') ? 'latitude' : 'longitude');

    this.setState({
      [name]: formattedInput,
      [name + '_error']: !isValid,
    });
  };

  maskCoordinateInput = (input, type) => {
    let cleanInput = input.replace(/[^0-9NSWE.,'°"\s]/gi, '').trim();
    let parts = cleanInput.split(/[\s°'"]/).filter(Boolean);

    let formattedInput = '';

    if (parts[0]) {
      formattedInput += parts[0].padStart(type === 'latitude' ? 2 : 3, '0') + '°';
    }
    if (parts[1]) {
      formattedInput += parts[1].padStart(2, '0') + '\'';
    }
    if (parts[2]) {
      formattedInput += parts[2].padStart(2, '0');
      if (parts[2].includes('.')) {
        let decimals = parts[2].split('.')[1];
        formattedInput += '.' + decimals;
      }
      formattedInput += '"';
    }

    let direction = parts.length === 4 ? parts[3].toUpperCase() : '';
    if (!direction && type === 'latitude') {
      direction = 'S';
    } else if (!direction && type === 'longitude') {
      direction = 'W';
    }
    if (direction && /[NSWE]/.test(direction)) {
      formattedInput += ' ' + direction;
    }

    let latitudeRegex = /^([0-8]?[0-9]|90)°([0-5]?[0-9])'([0-5]?[0-9](\.\d{1,})?)"(\s?[NS])$/;
    let longitudeRegex = /^([0-9]?[0-9]|1[0-7][0-9]|180)°([0-5]?[0-9])'([0-5]?[0-9](\.\d{1,})?)"(\s?[EW])$/;

    let isValid = type === 'latitude' ? latitudeRegex.test(formattedInput) : longitudeRegex.test(formattedInput);

    return { formattedInput, isValid };
  };

  downloadRelMAPA = () => {
    let apiUrl = Config.server + '/relmapa';
    const formData = new URLSearchParams();
    formData.append('data', JSON.stringify(this.state.jobReport));
    this.setState({ reportGenerated: true });

    let options = {
      method: 'POST',
      body: formData,
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt'),
      }
    };

    fetch(apiUrl, options)
      .then((res) => res.json())
      .then((data) => {
        const pdfData = data.pdfData;
        const guid = data.guid;

        if (pdfData && pdfData.length > 0) {
          const byteCharacters = atob(pdfData);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: 'application/pdf' });

          const pdfUrl = URL.createObjectURL(blob);
          const downloadLink = document.createElement('a');
          downloadLink.href = pdfUrl;
          downloadLink.download = `${this.state.title}-Relatorio-MAPA.pdf`;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
          URL.revokeObjectURL(pdfUrl);

          this.setState({ documentGuid: guid, reportGenerated: false }, () => {
            this.createDocument();
          });
        } else {
          notification.open({
            message: this.props.res.ERRO,
            description: this.props.res.ERRO_MSG,
            icon: <MaterialIcon icon="error" className="text-danger" />
          });
          this.setState({ reportGenerated: false });
        }
      })
      .catch((error) => {
        notification.open({
          message: this.props.res.ERRO,
          description: this.props.res.ERRO_MSG,
          icon: <MaterialIcon icon="error" className="text-danger" />
        });
        this.setState({ reportGenerated: false });
      });
  };

  loadCultures = () => {
    const apiUrl = Config.server + '/domain';

    const options = {
      method: 'GET',
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt'),
        'domain': "CULTURA"
      }
    }

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {
          this.setState({
            cultureData: result
          });
        },
        (error) => {
          if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            this.props.link('/login');
          }
          else {
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
        }
      );
  }

  getProd = () => {
    return new Promise((resolve, reject) => {
      const apiUrl = `${Config.server}/product`;
      const options = {
        method: 'GET',
        headers: {
          'Authorization': localStorage.getItem('access-token-jwt')
        }
      };

      fetch(apiUrl, options)
        .then((res) => {
          if (res.status !== 200) throw res;
          else return res.json();
        })
        .then((result) => {
          if (Array.isArray(result)) {
            const selectedProductIds = this.state.product.map(p => p.id);
            const sortedProducts = result
              .map((item, key) => ({ ...item, index: key }))
              .sort((a, b) => {
                const aSelected = selectedProductIds.includes(a.id);
                const bSelected = selectedProductIds.includes(b.id);
                return aSelected === bSelected ? 0 : aSelected ? -1 : 1;
              });

            this.setState({
              list_all_prod: sortedProducts,
              filteredProducts: sortedProducts
            }, resolve);
          }
        })
        .catch((error) => {
          if (error.status === 401) {
            this.props.link('/login');
          } else {
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
            reject(error);
          }
        });
    });
  }

  renderPaginationControls() {
    const pageCount = Math.ceil(this.state.filteredProducts.length / this.state.itemsPerPage);

    return (
      <div style={{ textAlign: 'center' }}>
        <Button
          onClick={this.prevPage}
          disabled={this.state.currentPage === 1 || this.state.editData === false}
        >
          <ArrowLeftIcon style={{ fontSize: '2rem' }} />
        </Button>
        <Button
          onClick={this.nextPage}
          disabled={this.state.currentPage === pageCount || this.state.editData === false}
        >
          <ArrowRightIcon style={{ fontSize: '2rem' }} />
        </Button>
      </div>
    );
  }

  nextPage = () => {
    this.setState(prevState => ({
      currentPage: prevState.currentPage + 1
    }));
  }

  prevPage = () => {
    this.setState(prevState => ({
      currentPage: prevState.currentPage - 1
    }));
  }

  handleFilterChange = (e) => {
    const filter = e.target.value;
    let filteredProducts;

    if (filter) {
      filteredProducts = this.state.list_all_prod.filter(product =>
        product.name.toLowerCase().includes(filter.toLowerCase())
      );
    } else {
      filteredProducts = this.state.list_all_prod;
    }

    this.setState({ filter, filteredProducts });
  }

  handleProductChange = (product) => {
    this.setState(prevState => {
      const maxProducts = 6;
      const productIndex = prevState.product.findIndex(p => p.id === product.id);
      let newProducts = prevState.product;

      if (productIndex > -1) {
        newProducts = [
          ...prevState.product.slice(0, productIndex),
          ...prevState.product.slice(productIndex + 1)
        ];
      } else {
        if (prevState.product.length < maxProducts) {
          newProducts = [...prevState.product, product];
        } else {
          notification.open({
            message: this.props.res.ATENCAO,
            description: this.props.res.ERRO_MAX_PRODUTO,
            icon: <MaterialIcon icon="error_outline" className="text-danger" />
          });
        }
      }

      return { product: newProducts };
    });
  };

  handleFieldChange = (productId, fieldName, value) => {
    this.setState(prevState => ({
      product: prevState.product.map(p => {
        if (p.id === productId) {
          return { ...p, [fieldName]: value };
        }
        return p;
      })
    }));
  };

  handleChangeDetails = (name) => (event) => {
    const { value } = event.target;
    this.setState(prevState => ({
      equipment: {
        ...prevState.equipment,
        details: {
          ...prevState.equipment.details,
          [name]: value
        }
      }
    }));
  };

  renderEquipmentDetailsInput = () => {
    switch (this.state.equipment.type) {
      case 'bicoHidraulicoCoreDisco':
        return (
          <div style={{ display: 'flex' }}>
            <TextField
              id="equipment"
              label="Equipamento"
              style={{ marginTop: '1rem', width: '50%', paddingRight: '1rem' }}
              value={this.state.equipment.name}
              onChange={this.handleChange('name')}
              disabled={!this.state.editData}
            />
            <TextField
              id="core"
              label="Core"
              style={{ marginTop: '1rem', width: '50%', paddingRight: '1rem' }}
              value={this.state.equipment.details ? this.state.equipment.details.core : ''}
              onChange={this.handleChangeDetails('core')}
              disabled={!this.state.editData}
            />
            <TextField
              id="disco"
              label="Disco"
              style={{ marginTop: '1rem', width: '50%', paddingRight: '1rem' }}
              value={this.state.equipment.details ? this.state.equipment.details.disco : ''}
              onChange={this.handleChangeDetails('disco')}
              disabled={!this.state.editData}
            />
            <TextField
              id="angulo"
              label="Ângulo"
              style={{ marginTop: '1rem', width: '50%' }}
              value={this.state.equipment.details ? this.state.equipment.details.angulo : ''}
              onChange={this.handleChangeDetails('angulo')}
              disabled={!this.state.editData}
            />
          </div>
        );
      case 'bicoHidraulicoCeramica':
        return (
          <div style={{ display: 'flex' }}>
            <TextField
              id="equipment"
              label="Equipamento"
              style={{ marginTop: '1rem', width: '50%', paddingRight: '1rem' }}
              value={this.state.equipment.name}
              onChange={this.handleChange('name')}
              disabled={!this.state.editData}
            />
            <TextField
              id="ponta"
              label="Ponta"
              style={{ marginTop: '1rem', width: '50%', paddingRight: '1rem' }}
              value={this.state.equipment.details ? this.state.equipment.details.ponta : ''}
              onChange={this.handleChangeDetails('ponta')}
              disabled={!this.state.editData}
            />
            <TextField
              id="angulo"
              label="Ângulo"
              style={{ marginTop: '1rem', width: '50%' }}
              value={this.state.equipment.details ? this.state.equipment.details.angulo : ''}
              onChange={this.handleChangeDetails('angulo')}
              disabled={!this.state.editData}
            />
          </div>
        );
      case 'atomizadorTela':
        return (
          <div style={{ display: 'flex' }}>
            <TextField
              id="equipment"
              label="Equipamento"
              style={{ marginTop: '1rem', width: '50%', paddingRight: '1rem' }}
              value={this.state.equipment.name}
              onChange={this.handleChange('name')}
              disabled={!this.state.editData}
            />
            <TextField
              id="anguloPa"
              label="Ângulo Pá"
              style={{ marginTop: '1rem', width: '50%', paddingRight: '1rem' }}
              value={this.state.equipment.details ? this.state.equipment.details.anguloPa : ''}
              onChange={this.handleChangeDetails('anguloPa')}
              disabled={!this.state.editData}
            />
            <TextField
              id="malha"
              label="Malha"
              style={{ marginTop: '1rem', width: '50%', paddingRight: '1rem' }}
              value={this.state.equipment.details ? this.state.equipment.details.malha : ''}
              onChange={this.handleChangeDetails('malha')}
              disabled={!this.state.editData}
            />
            <TextField
              id="vru"
              label="VRU"
              style={{ marginTop: '1rem', width: '50%' }}
              value={this.state.equipment.details ? this.state.equipment.details.vru : ''}
              onChange={this.handleChangeDetails('vru')}
              disabled={!this.state.editData}
            />
          </div>
        );
      case 'atomizadorDisco':
        return (
          <div style={{ display: 'flex' }}>
            <TextField
              id="equipment"
              label="Equipamento"
              style={{ marginTop: '1rem', width: '50%', paddingRight: '1rem' }}
              value={this.state.equipment.name}
              onChange={this.handleChange('name')}
              disabled={!this.state.editData}
            />
            <TextField
              id="anguloPa"
              label="Ângulo Pá"
              style={{ marginTop: '1rem', width: '50%', paddingRight: '1rem' }}
              value={this.state.equipment.details ? this.state.equipment.details.anguloPa : ''}
              onChange={this.handleChangeDetails('anguloPa')}
              disabled={!this.state.editData}
            />
            <TextField
              id="vru"
              label="VRU"
              style={{ marginTop: '1rem', width: '50%' }}
              value={this.state.equipment.details ? this.state.equipment.details.vru : ''}
              onChange={this.handleChangeDetails('vru')}
              disabled={!this.state.editData}
            />
          </div>
        );
      case 'bicoHidraulicoSolido':
        return (
          <div style={{ display: 'flex' }}>
            <TextField
              id="equipment"
              label="Equipamento"
              style={{ marginTop: '1rem', width: '50%', paddingRight: '1rem' }}
              value={this.state.equipment.name}
              onChange={this.handleChange('name')}
              disabled={!this.state.editData}
            />
            <TextField
              id="ponta"
              label="Ponta"
              style={{ marginTop: '1rem', width: '50%', paddingRight: '1rem' }}
              value={this.state.equipment.details ? this.state.equipment.details.ponta : ''}
              onChange={this.handleChangeDetails('ponta')}
              disabled={!this.state.editData}
            />
            <TextField
              id="angulo"
              label="Ângulo"
              style={{ marginTop: '1rem', width: '50%' }}
              value={this.state.equipment.details ? this.state.equipment.details.angulo : ''}
              onChange={this.handleChangeDetails('angulo')}
              disabled={!this.state.editData}
            />
          </div>
        );
      case 'bicoHidraulicoPlano':
        return (
          <div style={{ display: 'flex' }}>
            <TextField
              id="equipment"
              label="Equipamento"
              style={{ marginTop: '1rem', width: '50%', paddingRight: '1rem' }}
              value={this.state.equipment.name}
              onChange={this.handleChange('name')}
              disabled={!this.state.editData}
            />
            <TextField
              id="ponta"
              label="Ponta"
              style={{ marginTop: '1rem', width: '50%', paddingRight: '1rem' }}
              value={this.state.equipment.details ? this.state.equipment.details.ponta : ''}
              onChange={this.handleChangeDetails('ponta')}
              disabled={!this.state.editData}
            />
            <TextField
              id="angulo"
              label="Ângulo"
              style={{ marginTop: '1rem', width: '50%' }}
              value={this.state.equipment.details ? this.state.equipment.details.angulo : ''}
              onChange={this.handleChangeDetails('angulo')}
              disabled={!this.state.editData}
            />
          </div>
        );
      case 'cp03tt03':
        return (
          <div style={{ display: 'flex' }}>
            <TextField
              id="equipment"
              label="Equipamento"
              style={{ marginTop: '1rem', width: '50%', paddingRight: '1rem' }}
              value={this.state.equipment.name}
              onChange={this.handleChange('name')}
              disabled={!this.state.editData}
            />
            <TextField
              id="orificio"
              label="Orifício"
              style={{ marginTop: '1rem', width: '50%', paddingRight: '1rem' }}
              value={this.state.equipment.details ? this.state.equipment.details.orificio : ''}
              onChange={this.handleChangeDetails('orificio')}
              disabled={!this.state.editData}
            />
            <TextField
              id="angulo"
              label="Ângulo"
              style={{ marginTop: '1rem', width: '50%' }}
              value={this.state.equipment.details ? this.state.equipment.details.angulo : ''}
              onChange={this.handleChangeDetails('angulo')}
              disabled={!this.state.editData}
            />
          </div>
        );
      case 'cp11tt11':
        return (
          <div style={{ display: 'flex' }}>
            <TextField
              id="equipment"
              label="Equipamento"
              style={{ marginTop: '1rem', width: '50%', paddingRight: '1rem' }}
              value={this.state.equipment.name}
              onChange={this.handleChange('name')}
              disabled={!this.state.editData}
            />
            <TextField
              id="ponta"
              label="Ponta"
              style={{ marginTop: '1rem', width: '50%', paddingRight: '1rem' }}
              value={this.state.equipment.details ? this.state.equipment.details.ponta : ''}
              onChange={this.handleChangeDetails('ponta')}
              disabled={!this.state.editData}
            />
            <TextField
              id="angulo"
              label="Ângulo"
              style={{ marginTop: '1rem', width: '50%' }}
              value={this.state.equipment.details ? this.state.equipment.details.angulo : ''}
              onChange={this.handleChangeDetails('angulo')}
              disabled={!this.state.editData}
            />
          </div>
        );
      case 'cp09tt09':
        return (
          <div style={{ display: 'flex' }}>
            <TextField
              id="equipment"
              label="Equipamento"
              style={{ marginTop: '1rem', width: '50%', paddingRight: '1rem' }}
              value={this.state.equipment.name}
              onChange={this.handleChange('name')}
              disabled={!this.state.editData}
            />
            <TextField
              id="orificio"
              label="Orifício"
              style={{ marginTop: '1rem', width: '50%', paddingRight: '1rem' }}
              value={this.state.equipment.details ? this.state.equipment.details.orificio : ''}
              onChange={this.handleChangeDetails('orificio')}
              disabled={!this.state.editData}
            />
            <TextField
              id="angulo"
              label="Ângulo"
              style={{ marginTop: '1rem', width: '50%' }}
              value={this.state.equipment.details ? this.state.equipment.details.angulo : ''}
              onChange={this.handleChangeDetails('angulo')}
              disabled={!this.state.editData}
            />
          </div>
        );
      case 'outro':
        return (
          <div style={{ display: 'flex' }}>
            <TextField
              id="equipment"
              label="Equipamento"
              style={{ marginTop: '1rem', width: '50%', paddingRight: '1rem' }}
              value={this.state.equipment.name}
              onChange={this.handleChange('name')}
              disabled={!this.state.editData}
            />
            <TextField
              id="ponta"
              label="Ponta"
              style={{ marginTop: '1rem', width: '50%', paddingRight: '1rem' }}
              value={this.state.equipment.details ? this.state.equipment.details.ponta : ''}
              onChange={this.handleChangeDetails('ponta')}
              disabled={!this.state.editData}
            />
            <TextField
              id="angulo"
              label="Ângulo"
              style={{ marginTop: '1rem', width: '50%' }}
              value={this.state.equipment.details ? this.state.equipment.details.angulo : ''}
              onChange={this.handleChangeDetails('angulo')}
              disabled={!this.state.editData}
            />
          </div>
        );
      default:
        return null;
    }
  };

  handleCheckboxChange = (name) => {
    this.setState((prevState) => ({
      applicationConditions: {
        ...prevState.applicationConditions,
        [name]: !prevState.applicationConditions[name]
      }
    }));
  };

  handleInputChange = (name, value) => {
    this.setState((prevState) => ({
      applicationConditions: {
        ...prevState.applicationConditions,
        [name]: value
      }
    }));
  };

  handleDateChange = (date) => {
    this.setState(prevState => ({
      applicationConditions: {
        ...prevState.applicationConditions,
        data: date
      }
    }));
  };

  handleTypeServiceChange = (name) => {
    this.setState((prevState) => ({
      type_service: {
        ...prevState.type_service,
        [name]: !prevState.type_service[name]
      }
    }));
  };

  drawPolygon = () => {
    const canvas = document.getElementById('polygon-canvas');
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    if (!this.state.polygon || this.state.polygon.length === 0) return;

    const polygons = this.state.polygon.map(p => JSON.parse(p));

    const width = canvas.width;
    const height = canvas.height;

    const greenFlag = new Image();
    greenFlag.src = img7;

    const redFlag = new Image();
    redFlag.src = img8;

    greenFlag.onload = () => {
      redFlag.onload = () => {
        let globalMinLat = Infinity, globalMaxLat = -Infinity, globalMinLon = Infinity, globalMaxLon = -Infinity;
        polygons.forEach(coords => {
          coords.forEach(coord => {
            globalMinLat = Math.min(globalMinLat, coord[0]);
            globalMaxLat = Math.max(globalMaxLat, coord[0]);
            globalMinLon = Math.min(globalMinLon, coord[1]);
            globalMaxLon = Math.max(globalMaxLon, coord[1]);
          });
        });

        const globalWidth = globalMaxLon - globalMinLon;
        const globalHeight = globalMaxLat - globalMinLat;

        const scaleX = (width - 20) / globalWidth;
        const scaleY = (height - 20) / globalHeight;
        const scale = Math.min(scaleX, scaleY);

        const offsetX = (width - globalWidth * scale) / 2;
        const offsetY = (height - globalHeight * scale) / 2;

        polygons.forEach((coords, index) => {
          ctx.beginPath();
          coords.forEach((coord, idx) => {
            const x = (coord[1] - globalMinLon) * scale + offsetX;
            const y = height - ((coord[0] - globalMinLat) * scale + offsetY);
            if (idx === 0) {
              ctx.moveTo(x, y);
            } else {
              ctx.lineTo(x, y);
            }
          });
          ctx.closePath();
          ctx.fillStyle = 'lightgray';
          ctx.fill();
          ctx.strokeStyle = 'black';
          ctx.stroke();

          if (this.state.areaData && this.state.areaData[index]) {
            const area = this.state.areaData[index];
            if (area.startPoint && area.endPoint) {
              const startX = (area.startPoint.lng - globalMinLon) * scale + offsetX;
              const startY = height - ((area.startPoint.lat - globalMinLat) * scale + offsetY);
              const endX = (area.endPoint.lng - globalMinLon) * scale + offsetX;
              const endY = height - ((area.endPoint.lat - globalMinLat) * scale + offsetY);

              ctx.beginPath();
              ctx.moveTo(startX, startY);
              ctx.lineTo(endX, endY);
              ctx.strokeStyle = 'orangered';
              ctx.stroke();

              ctx.drawImage(greenFlag, startX - 10, startY - 10, 20, 20);
              ctx.drawImage(redFlag, endX - 10, endY - 10, 20, 20);
            }
          }
        });
      };
    };
  };

  handleOpenModal = () => {
    this.setState({ openModal: true });
  };

  handleCloseModal = () => {
    this.setState({ openModal: false });
  };

  getStatusDetails = (part) => {
    if (part.assinado && part.assinado.created) {
      return { icon: <CheckCircleIcon style={{ color: 'limegreen' }} />, etapa: 'Assinado', date: part.assinado.created };
    } else if (part.rejeitado && Object.keys(part.rejeitado).length > 0) {
      return { icon: <CancelIcon style={{ color: 'red' }} />, etapa: 'Rejeitado', date: part.rejeitado.created };
    } else if (part.visualizado && Object.keys(part.visualizado).length > 0) {
      return { icon: <VisibilityIcon style={{ color: 'gold' }} />, etapa: 'Visualizado', date: part.visualizado.created };
    } else if (part.mail && part.mail.sent) {
      return { icon: <EmailIcon />, etapa: 'Enviado', date: part.mail.sent };
    } else {
      return { icon: null, etapa: 'Indefinido', date: null };
    }
  };

  getNameFromEmail = (email) => {
    if (email === this.state.contractorEmail) return this.state.contractor;
    if (email === this.state.pilotEmail) return this.state.pilot;
    if (email === this.state.engineerEmail) return this.state.agricultural_engineer;
    if (email === this.state.executorEmail) return this.state.executor;

    return 'Nome não disponível';
  };

  getFunctionFromEmail = (email) => {
    if (email === this.state.contractorEmail) return 'Contratante';
    if (email === this.state.pilotEmail) return 'Piloto';
    if (email === this.state.engineerEmail) return 'Eng. Agrônomo';
    if (email === this.state.executorEmail) return 'Executor';

    return 'Função não disponível';
  };

  handleDeleteSignature = () => {
    const apiUrl = Config.server + `/signature/${this.state.documentId}`;
    const formData = new URLSearchParams();
    let descr = '';

    formData.append('id_document', this.state.documentId);
    descr = this.props.res.ITEM_EXCLUIDO;

    const options = {
      method: 'DELETE',
      body: formData,
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    };

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {
          this.setState({ documentId: '', })
          this.handleDelete();
        },
        (error) => {
          console.log('error', error);
        }
      );
  };

  handleDelete = () => {
    const apiUrl = Config.server + `/document/${this.state.documentGuid}`;
    const formData = new URLSearchParams();
    let descr = '';

    formData.append('fileGuid', this.state.documentGuid);
    descr = this.props.res.ITEM_EXCLUIDO;

    const options = {
      method: 'DELETE',
      body: formData,
      headers: {
        'Authorization': localStorage.getItem('access-token-jwt')
      }
    };

    fetch(apiUrl, options)
      .then((res) => {
        if (res.status !== 200) throw res;
        else return res.json();
      })
      .then(
        (result) => {
          notification.open({
            message: this.props.res.SUCESSO,
            description: descr,
            icon: <MaterialIcon icon="check_circle" className="text-success" />
          });
          this.setState({ documentData: {}, documentId: '', fileSigned: '' })
          this.handleClose();
          this.handleCloseModal();
        },
        (error) => {
          if (error.status === 405) {
            notification.open({
              message: this.props.res.PERMISSAO,
              description: this.props.res.PERMISSAO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          } else if (error.status === 401 || error.status === 403) {
            this.props.link('/login');
          } else {
            notification.open({
              message: this.props.res.ERRO,
              description: this.props.res.ERRO_MSG,
              icon: <MaterialIcon icon="error" className="text-danger" />
            });
          }
          this.handleClose();
        }
      );
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  downloadReportSigned = () => {
    window.open(this.state.fileSigned, '_blank');
    this.createDocument();
  }

  render() {
    const { res } = this.props;

    const indexOfLastItem = this.state.currentPage * this.state.itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - this.state.itemsPerPage;
    const currentItems = this.state.filteredProducts.slice(indexOfFirstItem, indexOfLastItem);

    const directionOptions = [
      { value: 'N', label: 'Norte (N)', icon: <ArrowRightAltIcon style={{ transform: 'rotate(270deg)' }} /> },
      { value: 'NE', label: 'Nordeste (NE)', icon: <ArrowRightAltIcon style={{ transform: 'rotate(315deg)' }} /> },
      { value: 'L', label: 'Leste (L)', icon: <ArrowRightAltIcon /> },
      { value: 'SE', label: 'Sudeste (SE)', icon: <ArrowRightAltIcon style={{ transform: 'rotate(45deg)' }} /> },
      { value: 'S', label: 'Sul (S)', icon: <ArrowRightAltIcon style={{ transform: 'rotate(90deg)' }} /> },
      { value: 'SO', label: 'Sudoeste (SO)', icon: <ArrowRightAltIcon style={{ transform: 'rotate(135deg)' }} /> },
      { value: 'O', label: 'Oeste (O)', icon: <ArrowRightAltIcon style={{ transform: 'rotate(180deg)' }} /> },
      { value: 'NO', label: 'Noroeste (NO)', icon: <ArrowRightAltIcon style={{ transform: 'rotate(225deg)' }} /> },
    ];

    const appDirectionOptions = [
      { value: 'N', label: 'Norte (N)', icon: <ArrowRightAltIcon style={{ transform: 'rotate(270deg)' }} /> },
      { value: 'NNE', label: 'Nor-Nordeste (NNE)', icon: <ArrowRightAltIcon style={{ transform: 'rotate(292.5deg)' }} /> },
      { value: 'NE', label: 'Nordeste (NE)', icon: <ArrowRightAltIcon style={{ transform: 'rotate(315deg)' }} /> },
      { value: 'ENE', label: 'És-Nordeste (ENE)', icon: <ArrowRightAltIcon style={{ transform: 'rotate(337.5deg)' }} /> },
      { value: 'L', label: 'Leste (L)', icon: <ArrowRightAltIcon /> },
      { value: 'ESE', label: 'És-Sudeste (ESE)', icon: <ArrowRightAltIcon style={{ transform: 'rotate(22.5deg)' }} /> },
      { value: 'SE', label: 'Sudeste (SE)', icon: <ArrowRightAltIcon style={{ transform: 'rotate(45deg)' }} /> },
      { value: 'SSE', label: 'Su-Sudeste (SSE)', icon: <ArrowRightAltIcon style={{ transform: 'rotate(67.5deg)' }} /> },
      { value: 'S', label: 'Sul (S)', icon: <ArrowRightAltIcon style={{ transform: 'rotate(90deg)' }} /> },
      { value: 'SSO', label: 'Su-Sudoeste (SSO)', icon: <ArrowRightAltIcon style={{ transform: 'rotate(112.5deg)' }} /> },
      { value: 'SO', label: 'Sudoeste (SO)', icon: <ArrowRightAltIcon style={{ transform: 'rotate(135deg)' }} /> },
      { value: 'OSO', label: 'Oés-Sudoeste (OSO)', icon: <ArrowRightAltIcon style={{ transform: 'rotate(157.5deg)' }} /> },
      { value: 'O', label: 'Oeste (O)', icon: <ArrowRightAltIcon style={{ transform: 'rotate(180deg)' }} /> },
      { value: 'ONO', label: 'Oés-Noroeste (ONO)', icon: <ArrowRightAltIcon style={{ transform: 'rotate(202.5deg)' }} /> },
      { value: 'NO', label: 'Noroeste (NO)', icon: <ArrowRightAltIcon style={{ transform: 'rotate(225deg)' }} /> },
      { value: 'NNO', label: 'Nor-Noroeste (NNO)', icon: <ArrowRightAltIcon style={{ transform: 'rotate(247.5deg)' }} /> },
    ];

    const partes = this.state.documentData.partes || [];
    const allSigned = partes.every(part => part.assinado && part.assinado.created);

    return (
      <div>
        <div className="containerTop">
          <div><h1>{res.RELATORIO_MAPA}</h1></div>
          <div className="centerTop"></div>
          <div>
            <Button variant="contained" disabled={this.state.editData && this.state.jobReportId !== null} onClick={() => this.props.link('/app/relatorio-mapa/list')} className="popUpButton">
              {res.CANCELAR}
            </Button> &nbsp;
            {this.state.documentData.partes && this.state.documentData.partes.length > 0 ? (
              <Button
                variant="contained"
                color="primary"
                className="popUpButton"
                onClick={this.handleOpenModal}
              >
                <DescriptionIcon className="iconMapS" />
                {res.ACOMPANHAR_ASSINATURA}
              </Button>
            ) : (
              <Button
                variant="contained"
                disabled={!this.state.isReportReady || (this.state.editData && this.state.reportGenerated)}
                color={this.state.reportGenerated ? "default" : "primary"}
                className="popUpButton"
                onClick={this.downloadRelMAPA}
              >
                {this.state.reportGenerated ? (
                  <CircularProgress size={24} />
                ) : (
                  <>
                    <DescriptionIcon className="iconMapS" />
                    {res.GERAR_RELATORIO}
                  </>
                )}
              </Button>
            )}
            <Modal
              open={this.state.openModal}
              onClose={this.handleCloseModal}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <Paper style={{ maxWidth: '80%', overflow: 'auto' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#2D3238', padding: '0.5rem 0' }}>
                  <div style={{ width: '33%', textAlign: 'left' }}></div>
                  <div style={{ width: '33%', textAlign: 'center' }}>
                    <h2 style={{ color: 'white' }}>{res.DADOS_DOCUMENTO}</h2>
                  </div>
                  <div style={{ width: '33%', textAlign: 'right' }}>
                    <Button size="small" className="popUpButton" onClick={this.handleCloseModal}>
                      <CloseIcon style={{ color: 'white' }} />
                    </Button>
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'right', padding: '1rem' }}>
                  {allSigned && (
                    <Button
                      variant="contained"
                      color="primary"
                      className="popUpButton"
                      onClick={this.downloadReportSigned}
                      style={{ paddingTop: '0.75rem' }}
                    >
                      <DescriptionIcon className="iconMapS" />
                      {res.BAIXAR_RELATORIO}
                    </Button>
                  )} &nbsp;
                  <Button
                    variant="contained"
                    color="primary"
                    className="popUpButton"
                    onClick={this.readDocument}
                    style={{ paddingTop: '0.75rem' }}
                  >
                    <RefreshIcon className="iconMapS" />
                  </Button> &nbsp;
                  <Button
                    variant="contained"
                    style={{ backgroundColor: 'red', color: 'white', paddingTop: '0.75rem' }}
                    className="popUpButton"
                    onClick={this.handleClickOpen}
                  >
                    <DeleteForeverIcon className="iconMapS" />
                  </Button>
                  <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                  >
                    <DialogTitle style={{ padding: '0' }}>
                      <div style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#2D3238', padding: '0.5rem 0' }}>
                        <div style={{ width: '16%', textAlign: 'left' }}></div>
                        <div style={{ textAlign: 'center' }}>
                          <p style={{ color: 'white', margin: '0' }}>{res.EXCLUIR_DOCUMENTO}</p>
                        </div>
                        <div style={{ width: '16%', textAlign: 'right' }}>
                          <Button size="small" className="popUpButton" onClick={this.handleClose}>
                            <CloseIcon style={{ color: 'white' }} />
                          </Button>
                        </div>
                      </div>
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText style={{ color: 'black' }}>{res.CONFIRMAR_EXCLUSÃO}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={this.handleClose} color="primary">
                        {res.NAO}
                      </Button>
                      <Button onClick={this.handleDeleteSignature} color="primary" autoFocus>
                        {res.SIM}
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{res.NOME}</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{res.E_MAIL}</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{res.FUNCAO}</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{res.ETAPA}</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{res.STATUS_ASSINATURA}</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{res.ULTIMA_ATUALIZACAO}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {partes.length > 0 ? (
                      partes.map((part) => {
                        const { icon, etapa, date } = this.getStatusDetails(part);
                        const nome = part.nome || this.getNameFromEmail(part.email) || 'Nome não disponível';
                        const funcao = this.getFunctionFromEmail(part.email);
                        return (
                          <TableRow key={part.uuid}>
                            <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{nome}</TableCell>
                            <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{part.email || 'E-mail não disponível'}</TableCell>
                            <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{funcao}</TableCell>
                            <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{etapa}</TableCell>
                            <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{icon}</TableCell>
                            <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{date ? Moment(date).format('DD/MM/YYYY HH:mm') : 'Data não disponível'}</TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell colSpan={6} align="center">
                          {res.NENHUM_DADO}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Paper>
            </Modal>
          </div>
        </div>
        <div style={{ textAlign: 'end', marginTop: '1rem' }}>
          {this.state.editData
            ?
            <div>
              <Button variant="contained" color="primary" onClick={this.handleSubmit} className="popUpButton">
                <SaveIcon className="iconMapS" />
                {res.SALVAR}
              </Button>
            </div>
            :
            <Button variant="contained" color="primary" onClick={() => this.setState({ editData: true })} className="popUpButton">
              <EditIcon className="iconMapS" />
              {res.EDITAR}
            </Button>
          }
        </div>
        <div style={{ margin: '1rem 0' }}>
          <div style={{ margin: '1rem 0' }}>
            <TextField
              id="title"
              label={res.TITULO}
              disabled={!this.state.editData}
              value={this.state.title}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <TextField
              label={res.DATA_RECEITUARIO}
              type="date"
              value={this.state.prescriptionDate}
              onChange={this.handleChange('prescriptionDate')}
              disabled={!this.state.editData}
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: '100%', marginTop: '1rem' }}
            />
            <TextField
              id="prescriptionNumber"
              label={res.NUMERO_RECEITUARIO}
              disabled={!this.state.editData}
              style={{ width: '100%', margin: '1rem' }}
              value={this.state.prescriptionNumber}
              onChange={this.handleChange('prescriptionNumber')}
            />
            <TextField
              id="registrationNumber"
              label={res.NUMERO_REGISTRO_MAPA}
              disabled={!this.state.editData}
              style={{ width: '100%', marginTop: '1rem' }}
              value={this.state.registrationNumber}
              onChange={this.handleChange('registrationNumber')}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <TextField
              id="contractor"
              label={res.CONTRATANTE}
              disabled={this.state.editData === false}
              style={{ marginTop: '1rem', width: '100%', paddingRight: '1rem' }}
              value={this.state.contractor}
              onChange={this.handleChange('contractor')}
            />
            <TextField
              id="cnpj-cpf"
              label={res.CPFCNPJ}
              disabled={this.state.editData === false}
              style={{ marginTop: '1rem', width: '100%', paddingRight: '1rem' }}
              value={this.state.cpf_cnpj}
              onChange={this.handleChange('cpf_cnpj')}
            />
            <TextField
              id="contractorEmail"
              label={res.EMAIL}
              disabled={this.state.editData === false}
              style={{ marginTop: '1rem', width: '100%' }}
              value={this.state.contractorEmail ? this.state.contractorEmail : ''}
              onChange={this.handleChange('contractorEmail')}
            />
          </div>
          <div>
            <FormControl style={{ marginTop: '1rem', width: '60%', paddingRight: '1rem' }} disabled={this.state.editData === false}>
              <InputLabel id="property-label">{res.PROPRIEDADE}</InputLabel>
              <Select
                labelId="property-label"
                id="property"
                value={this.state.property}
                onChange={this.handleChangeProperty}
              >
                {this.state.jobProperty.map((prop, index) => (
                  <MenuItem key={index} value={prop.property_name}>{prop.property_name}</MenuItem>
                ))}
                <MenuItem key={this.state.jobProperty.length} value={'Outro'}>{res.NAO_CADASTRADA}</MenuItem>
              </Select>
            </FormControl>
            <TextField
              id="city"
              label={res.CIDADE}
              disabled={this.state.editData === false}
              style={{ marginTop: '1rem', width: '40%' }}
              value={this.state.city ? this.state.city : ''}
              onChange={this.handleChange('city')}
            />
          </div>
          <div>
            <TextField
              id="address"
              label={res.ENDERECO}
              disabled={this.state.editData === false}
              style={{ marginTop: '1rem', width: '60%', paddingRight: '1rem' }}
              value={this.state.address ? this.state.address : ''}
              onChange={this.handleChange('address')}
            />
            <TextField
              id="uf"
              label={res.ESTADO}
              disabled={this.state.editData === false}
              style={{ marginTop: '1rem', width: '40%' }}
              value={this.state.uf ? this.state.uf : ''}
              onChange={this.handleChange('uf')}
              inputProps={{ maxLength: 2 }}
              helperText={this.state.uf.length === 0 || this.state.uf.length === 2 ? '' : 'Insira a UF do estado'}
            />
          </div>
          <div>
            <FormControl style={{ width: '100%' }} disabled={this.state.editData === false}>
              <InputLabel htmlFor="agriculturelCulture" style={{ paddingTop: '1rem' }}>{res.CULTURA}</InputLabel>
              <Select
                onChange={this.handleChange('culture')}
                value={this.state.culture}
                style={{ width: '100%', marginTop: '2rem' }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 224,
                      width: 250,
                    },
                  },
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
              >
                <MenuItem disabled value="">{res.SELECIONAR_CULTURA}</MenuItem>
                {this.state.cultureData.map((item) => (
                  <MenuItem key={item.name} value={item.name}>{item.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              id="area"
              label={res.AREA}
              disabled={this.state.editData === false}
              style={{ marginTop: '1rem', width: '33.33%', paddingRight: '1rem' }}
              value={this.state.area}
              onChange={this.handleChange('area')}
            />
            <TextField
              id="volume"
              label={res.VOLUME}
              disabled={this.state.editData === false}
              style={{ marginTop: '1rem', width: '33.33%' }}
              value={this.state.volume}
              onChange={this.handleChange('volume')}
            />
          </div>
          <div style={{ display: 'flex', alignItems: 'baseline' }}>
            <div style={{ display: 'flex', alignItems: 'baseline', width: '40%' }}>
              <TextField
                id="startDate"
                label={res.INICIO_APLICACAO}
                type="datetime-local"
                disabled={!this.state.editData}
                style={{ marginTop: '1rem', width: '50%', paddingRight: '1rem' }}
                value={this.state.startDate}
                onChange={this.handleChange('startDate')}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                id="endDate"
                label={res.FIM_APLICACAO}
                type="datetime-local"
                disabled={!this.state.editData}
                style={{ marginTop: '1rem', width: '50%', paddingRight: '1rem' }}
                value={this.state.endDate}
                onChange={this.handleChange('endDate')}
                InputLabelProps={{ shrink: true }}
              />
            </div>
            <TextField
              id="timeVoo"
              label={res.TEMPO_OPERACAO}
              type="time"
              disabled={!this.state.editData}
              style={{ marginTop: '1rem', width: '20%', paddingRight: '1rem' }}
              value={this.state.time_voo}
              onChange={this.handleChange('time_voo')}
              InputLabelProps={{ shrink: true }}
              inputProps={{ step: 1 }}
            />
            <FormControl style={{ display: 'flex', flexDirection: 'row', marginTop: '1rem', width: '40%' }} disabled={this.state.editData === false}>
              <FormLabel style={{ display: 'flex', alignItems: 'center', marginRight: '1rem' }}>{res.PERIODO_APLICACAO}:</FormLabel>
              <RadioGroup style={{ display: 'flex', flexDirection: 'row' }} value={this.state.application_period} onChange={this.handleChange('application_period')}>
                <FormControlLabel style={{ marginRight: '2rem' }} value="preEmergente" control={<Radio color="primary" />} label="Pré-Emergente" />
                <FormControlLabel style={{ marginRight: '2rem' }} value="posEmergente" control={<Radio color="primary" />} label="Pós-Emergente" />
              </RadioGroup>
            </FormControl>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <FormControl style={{ display: 'flex', flexDirection: 'row', marginTop: '1rem', width: '100%' }} disabled={this.state.editData === false}>
              <FormLabel style={{ display: 'flex', alignItems: 'center' }}>{res.CLASSE_AGROTOXICO}:</FormLabel>
              <div style={{ display: 'flex', alignItems: 'center', margin: '0 0.5rem' }}>
                <input
                  type="checkbox"
                  style={{ width: '1.25rem', height: '1.25rem', marginRight: '0.5rem', cursor: this.state.editData ? 'pointer' : null }}
                  checked={this.state.type_service.insecticide}
                  onChange={() => this.handleTypeServiceChange('insecticide')}
                  name="insecticide"
                  disabled={this.state.editData === false}
                />
                <span style={{ color: this.state.editData ? 'black' : 'rgba(0, 0, 0, 0.38)' }}>{res.INSETICIDA}</span>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', margin: '0 0.5rem' }}>
                <input
                  type="checkbox"
                  style={{ width: '1.25rem', height: '1.25rem', marginRight: '0.5rem', cursor: this.state.editData ? 'pointer' : null }}
                  checked={this.state.type_service.herbicide}
                  onChange={() => this.handleTypeServiceChange('herbicide')}
                  name="herbicide"
                  disabled={this.state.editData === false}
                />
                <span style={{ color: this.state.editData ? 'black' : 'rgba(0, 0, 0, 0.38)' }}>{res.HERBICIDA}</span>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', margin: '0 0.5rem' }}>
                <input
                  type="checkbox"
                  style={{ width: '1.25rem', height: '1.25rem', marginRight: '0.5rem', cursor: this.state.editData ? 'pointer' : null }}
                  checked={this.state.type_service.fungicide}
                  onChange={() => this.handleTypeServiceChange('fungicide')}
                  name="fungicide"
                  disabled={this.state.editData === false}
                />
                <span style={{ color: this.state.editData ? 'black' : 'rgba(0, 0, 0, 0.38)' }}>{res.FUNGICIDA}</span>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', margin: '0 0.5rem' }}>
                <input
                  type="checkbox"
                  style={{ width: '1.25rem', height: '1.25rem', marginRight: '0.5rem', cursor: this.state.editData ? 'pointer' : null }}
                  checked={this.state.type_service.seeding}
                  onChange={() => this.handleTypeServiceChange('seeding')}
                  name="seeding"
                  disabled={this.state.editData === false}
                />
                <span style={{ color: this.state.editData ? 'black' : 'rgba(0, 0, 0, 0.38)' }}>{res.SEMEADURA}</span>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', margin: '0 0.5rem' }}>
                <input
                  type="checkbox"
                  style={{ width: '1.25rem', height: '1.25rem', marginRight: '0.5rem', cursor: this.state.editData ? 'pointer' : null }}
                  checked={this.state.type_service.fertilizing}
                  onChange={() => this.handleTypeServiceChange('fertilizing')}
                  name="fertilizing"
                  disabled={this.state.editData === false}
                />
                <span style={{ color: this.state.editData ? 'black' : 'rgba(0, 0, 0, 0.38)' }}>{res.ADUBACAO}</span>
              </div>
            </FormControl>
            <FormControl style={{ width: '100%', marginTop: '1rem' }} disabled={this.state.editData === false}>
              <InputLabel htmlFor="type-service">{res.TIPO_SERVICO}</InputLabel>
              <Select
                value={this.state.selectedService}
                onChange={this.handleChange('selectedService')}
                style={{ width: '100%' }}
                inputProps={{
                  name: 'typeService',
                  id: 'type-service',
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
              >
                <MenuItem value="">{res.SELECIONE_TIPO_SERVICO}</MenuItem>
                <MenuItem value="AplicacaoAgrotoxico">{res.APLICACAO_AGROTOXICO}</MenuItem>
                <MenuItem value="AplicacaoFertilizante">{res.APLICACAO_FERTILIZANTE}</MenuItem>
                <MenuItem value="Semeadura">{res.SEMEADURA}</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div>
            <TextField
              id="temperature"
              label={res.TEMPERATURA_UNI}
              disabled={this.state.editData === false}
              style={{ marginTop: '1rem', width: '33.34%', paddingRight: '1rem' }}
              value={this.state.temperature}
              onChange={this.handleChange('temperature')}
            />
            <TextField
              id="humidity"
              label={res.HUMIDADE_UNI}
              disabled={this.state.editData === false}
              style={{ marginTop: '1rem', width: '33.33%', paddingRight: '1rem' }}
              value={this.state.humidity}
              onChange={this.handleChange('humidity')}
            />
            <TextField
              id="windSpeed"
              label={res.VELOCIDADE_VENTO}
              disabled={this.state.editData === false}
              style={{ marginTop: '1rem', width: '33.33%' }}
              value={this.state.wind_speed}
              onChange={this.handleChange('wind_speed')}
            />
          </div>
          <div>
            <TextField
              id="flightHeight"
              label={res.ALTURA_VOO}
              disabled={this.state.editData === false}
              style={{ marginTop: '1rem', width: '50%', paddingRight: '1rem' }}
              value={this.state.flight_height}
              onChange={this.handleChange('flight_height')}
            />
            <TextField
              id="applicationRange"
              label={res.FAIXA_UNI}
              disabled={this.state.editData === false}
              style={{ marginTop: '1rem', width: '50%' }}
              value={this.state.applicationRange}
              onChange={this.handleChange('applicationRange')}
            />
          </div>

          <div>
            <TextField
              id="plane"
              label={res.AERONAVE}
              disabled={this.state.editData === false}
              style={{ marginTop: '1rem', width: '100%' }}
              value={this.state.plane}
              onChange={this.handleChange('plane')}
            />
          </div>
          <div>
            <TextField
              id="pilot"
              label={res.PILOTO}
              disabled={this.state.editData === false}
              style={{ marginTop: '1rem', width: '40%', paddingRight: '1rem' }}
              value={this.state.pilot}
              onChange={this.handleChange('pilot')}
            />
            <TextField
              id="canac"
              label={res.CHT}
              disabled={this.state.editData === false}
              style={{ marginTop: '1rem', width: '30%', paddingRight: '1rem' }}
              value={this.state.canac}
              onChange={this.handleChange('canac')}
            />
            <TextField
              id="pilotEmail"
              label={res.EMAIL}
              disabled={this.state.editData === false}
              style={{ marginTop: '1rem', width: '30%' }}
              value={this.state.pilotEmail ? this.state.pilotEmail : ''}
              onChange={this.handleChange('pilotEmail')}
            />
          </div>
          <div>
            <div>
              <FormControl style={{ display: 'flex', flexDirection: 'row', marginTop: '1rem', width: '100%' }} disabled={!this.state.editData}>
                <FormLabel style={{ display: 'flex', alignItems: 'center', marginRight: '1rem' }}>{res.EQUIPAMENTO}:</FormLabel>
                <RadioGroup style={{ display: 'flex', flexDirection: 'row' }} value={this.state.equipment.type} onChange={this.handleChange('equipment')}>
                  <FormControlLabel style={{ marginRight: '2rem' }} value="bicoHidraulicoCoreDisco" control={<Radio color="primary" />} label="Bico Hidráulico Core e Disco" />
                  <FormControlLabel style={{ marginRight: '2rem' }} value="bicoHidraulicoCeramica" control={<Radio color="primary" />} label="Bico Hidráulico Cerâmica" />
                  <FormControlLabel style={{ marginRight: '2rem' }} value="bicoHidraulicoSolido" control={<Radio color="primary" />} label="Bico Hidráulico Jato Sólido" />
                  <FormControlLabel style={{ marginRight: '2rem' }} value="bicoHidraulicoPlano" control={<Radio color="primary" />} label="Bico Hidráulico Jato Plano" />
                  <FormControlLabel style={{ marginRight: '2rem' }} value="cp03tt03" control={<Radio color="primary" />} label="CP03/TT03" />
                  <FormControlLabel style={{ marginRight: '2rem' }} value="cp09tt09" control={<Radio color="primary" />} label="CP09/TT09" />
                  <FormControlLabel style={{ marginRight: '2rem' }} value="cp11tt11" control={<Radio color="primary" />} label="CP11/TT11" />
                  <FormControlLabel style={{ marginRight: '2rem' }} value="atomizadorTela" control={<Radio color="primary" />} label="Atomizador Rotativo Tela" />
                  <FormControlLabel style={{ marginRight: '2rem' }} value="atomizadorDisco" control={<Radio color="primary" />} label="Atomizador Rotativo Disco" />
                  <FormControlLabel style={{ marginRight: '2rem' }} value="swathmaster" control={<Radio color="primary" />} label="Swathmaster" />
                  <FormControlLabel style={{ marginRight: '2rem' }} value="outro" control={<Radio color="primary" />} label="Outro" />
                </RadioGroup>
              </FormControl>
              {this.renderEquipmentDetailsInput()}
            </div>
            <div>
              <div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '1rem 0' }}>
                  <span style={{ color: this.state.editData ? 'black' : 'rgba(0, 0, 0, 0.38)' }}>{res.LISTA_PRODUTOS}</span>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <input
                      type="text"
                      placeholder="Filtrar produtos"
                      value={this.state.filter}
                      onChange={this.handleFilterChange}
                      style={{ border: 'none', borderBottom: this.state.editData ? '1px solid black' : '1px solid rgba(0, 0, 0, 0.38)', outline: 'none', fontSize: '0.75rem', width: '6rem' }}
                      disabled={this.state.editData === false}
                    />
                    <SearchIcon style={{ color: this.state.editData ? 'black' : 'rgba(0, 0, 0, 0.38)', borderBottom: this.state.editData ? '1px solid black' : '1px solid rgba(0, 0, 0, 0.38)', marginTop: '-3px' }} />
                  </div>
                </div>
                <div style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'flex-start',
                  padding: '0',
                  margin: '0 -0.5rem',
                }}>
                  {currentItems.map(product => (
                    <div key={product.id} style={{
                      padding: '0.5rem',
                      width: 'calc(33.33% - 1rem)',
                      boxSizing: 'border-box',
                    }}
                    >
                      <label
                        style={{
                          display: 'block',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          color: this.state.editData ? 'black' : 'rgba(0, 0, 0, 0.38)'
                        }}
                        disabled={!this.state.editData}
                      >
                        <input
                          type="checkbox"
                          style={{ width: '1.25rem', height: '1.25rem', marginRight: '0.5rem', cursor: this.state.editData ? 'pointer' : null }}
                          checked={this.state.product.some(p => p.id === product.id)}
                          onChange={() => this.handleProductChange(product)}
                          disabled={!this.state.editData}
                        />
                        {product.name}
                      </label>
                      {this.state.product.some(p => p.id === product.id) && (
                        <div style={{ display: 'flex' }}>
                          <TextField
                            label="Dosagem"
                            value={this.state.product.find(p => p.id === product.id)?.dosage || ''}
                            onChange={(e) => this.handleFieldChange(product.id, 'dosage', e.target.value)}
                            disabled={!this.state.editData}
                            InputLabelProps={{
                              style: { fontSize: '0.8rem' }
                            }}
                            style={{ width: '6rem' }}
                          />
                          <TextField
                            label="Classe Toxicológica"
                            value={this.state.product.find(p => p.id === product.id)?.toxicClass || ''}
                            onChange={(e) => this.handleFieldChange(product.id, 'toxicClass', e.target.value)}
                            disabled={!this.state.editData}
                            InputLabelProps={{
                              style: { fontSize: '0.8rem' }
                            }}
                            style={{ margin: '0 0.5rem', width: 'auto' }}
                          />
                          <TextField
                            label="Taxa de Aplicação"
                            value={this.state.product.find(p => p.id === product.id)?.applicationRate || ''}
                            onChange={(e) => this.handleFieldChange(product.id, 'applicationRate', e.target.value)}
                            disabled={!this.state.editData}
                            InputLabelProps={{
                              style: { fontSize: '0.8rem' }
                            }}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
                {this.renderPaginationControls()}
              </div>
              {this.state.product.length > 0 ?
                <div style={{ marginBottom: '1rem', border: '1px solid gray', padding: '0.25rem' }}>
                  <span style={{ color: this.state.editData ? 'black' : 'rgba(0, 0, 0, 0.38)' }}>{res.PRODUTOS_SELECIONADOS}</span>
                  <div style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'flex-start',
                    padding: '0',
                    margin: '0 -0.5rem',
                  }}>
                    {this.state.product.map(product => (
                      <div key={product.id} style={{
                        padding: '0.5rem',
                        width: 'calc(33.33% - 1rem)',
                        boxSizing: 'border-box',
                      }}>
                        <label
                          style={{
                            display: 'block',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            color: this.state.editData ? 'black' : 'rgba(0, 0, 0, 0.38)'
                          }}
                          disabled={!this.state.editData}
                        >
                          <input
                            type="checkbox"
                            style={{ width: '1.25rem', height: '1.25rem', marginRight: '0.5rem', cursor: this.state.editData ? 'pointer' : null }}
                            checked={this.state.product.some(p => p.id === product.id)}
                            onChange={() => this.handleProductChange(product)}
                            disabled={!this.state.editData}
                          />
                          {product.name}
                        </label>
                        <div style={{ display: 'flex' }}>
                          <TextField
                            label="Dosagem"
                            value={product.dosage || ''}
                            onChange={(e) => this.handleFieldChange(product.id, 'dosage', e.target.value)}
                            disabled={!this.state.editData}
                            InputLabelProps={{
                              style: { fontSize: '0.8rem' }
                            }}
                            style={{ width: '6rem' }}
                          />
                          <TextField
                            label="Classe Toxicológica"
                            value={product.toxicClass || ''}
                            onChange={(e) => this.handleFieldChange(product.id, 'toxicClass', e.target.value)}
                            disabled={!this.state.editData}
                            InputLabelProps={{
                              style: { fontSize: '0.8rem' }
                            }}
                            style={{ margin: '0 0.5rem', width: 'auto' }}
                          />
                          <TextField
                            label="Taxa de Aplicação"
                            value={product.applicationRate || ''}
                            onChange={(e) => this.handleFieldChange(product.id, 'applicationRate', e.target.value)}
                            disabled={!this.state.editData}
                            InputLabelProps={{
                              style: { fontSize: '0.8rem' }
                            }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                : null}
            </div>
          </div>
          <div>
            <FormLabel style={{ display: 'flex', alignItems: 'center', marginRight: '1rem', color: this.state.editData ? 'black' : 'rgba(0, 0, 0, 0.38)' }}>{res.CONDICOES_APLICACAO}</FormLabel>
            <TextField
              label={res.DATA}
              type="date"
              value={this.state.applicationConditions.data}
              onChange={(e) => this.handleDateChange(e.target.value)}
              disabled={!this.state.editData}
              InputLabelProps={{
                shrink: true,
              }}
              style={{ marginTop: '1rem' }}
            />
            <div style={{ display: 'flex' }}>
              <div style={{ width: '25%' }}>
                <FormLabel style={{ display: 'flex', alignItems: 'center', marginTop: '1rem', marginRight: '1rem', color: this.state.editData ? 'black' : 'rgba(0, 0, 0, 0.38)' }}>{res.TEMPERATURA_}</FormLabel>
                <div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      type="checkbox"
                      style={{ width: '1.25rem', height: '1.25rem', marginRight: '0.5rem', cursor: this.state.editData ? 'pointer' : null }}
                      checked={this.state.applicationConditions.temperaturaMatutino}
                      onChange={() => this.handleCheckboxChange('temperaturaMatutino')}
                      disabled={this.state.editData === false}
                    />
                    <span style={{ color: this.state.editData ? 'black' : 'rgba(0, 0, 0, 0.38)' }}>{res.MATUTINO}</span>
                  </div>
                  {this.state.applicationConditions.temperaturaMatutino && (
                    <div>
                      <TextField
                        label={res.TEMPERATURA_INI_MATUTINO}
                        value={this.state.applicationConditions.temperaturaInicialMatutino}
                        onChange={(e) => this.handleInputChange('temperaturaInicialMatutino', e.target.value)}
                        disabled={!this.state.editData}
                        InputLabelProps={{
                          style: { fontSize: '0.8rem' }
                        }}
                        style={{ padding: '0 1rem 0 0', margin: '0.5rem 0', width: '100%' }}
                      />
                      <TextField
                        label={res.TEMPERATURA_FIN_MATUTINO}
                        value={this.state.applicationConditions.temperaturaFinalMatutino}
                        onChange={(e) => this.handleInputChange('temperaturaFinalMatutino', e.target.value)}
                        disabled={!this.state.editData}
                        InputLabelProps={{
                          style: { fontSize: '0.8rem' }
                        }}
                        style={{ padding: '0 1rem 0 0', margin: '0.5rem 0', width: '100%' }}
                      />
                    </div>
                  )}
                </div>
                <div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      type="checkbox"
                      style={{ width: '1.25rem', height: '1.25rem', marginRight: '0.5rem', cursor: this.state.editData ? 'pointer' : null }}
                      checked={this.state.applicationConditions.temperaturaVespertino}
                      onChange={() => this.handleCheckboxChange('temperaturaVespertino')}
                      disabled={!this.state.editData}
                    />
                    <span style={{ color: this.state.editData ? 'black' : 'rgba(0, 0, 0, 0.38)' }}>{res.VESPERTINO}</span>
                  </div>
                  {this.state.applicationConditions.temperaturaVespertino && (
                    <div>
                      <TextField
                        label={res.TEMPERATURA_INI_VESPERTINO}
                        value={this.state.applicationConditions.temperaturaInicialVespertino}
                        onChange={(e) => this.handleInputChange('temperaturaInicialVespertino', e.target.value)}
                        disabled={!this.state.editData}
                        InputLabelProps={{
                          style: { fontSize: '0.8rem' }
                        }}
                        style={{ padding: '0 1rem 0 0', margin: '0.5rem 0', width: '100%' }}
                      />
                      <TextField
                        label={res.TEMPERATURA_FIN_VESPERTINO}
                        value={this.state.applicationConditions.temperaturaFinalVespertino}
                        onChange={(e) => this.handleInputChange('temperaturaFinalVespertino', e.target.value)}
                        disabled={!this.state.editData}
                        InputLabelProps={{
                          style: { fontSize: '0.8rem' }
                        }}
                        style={{ padding: '0 1rem 0 0', margin: '0.5rem 0', width: '100%' }}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div style={{ width: '25%' }}>
                <FormLabel style={{ display: 'flex', alignItems: 'center', marginTop: '1rem', marginRight: '1rem', color: this.state.editData ? 'black' : 'rgba(0, 0, 0, 0.38)' }}>{res.UMIDADE_}</FormLabel>
                <div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      type="checkbox"
                      style={{ width: '1.25rem', height: '1.25rem', marginRight: '0.5rem', cursor: this.state.editData ? 'pointer' : null }}
                      checked={this.state.applicationConditions.umidadeMatutino}
                      onChange={() => this.handleCheckboxChange('umidadeMatutino')}
                      disabled={!this.state.editData}
                    />
                    <span style={{ color: this.state.editData ? 'black' : 'rgba(0, 0, 0, 0.38)' }}>{res.MATUTINO}</span>
                  </div>
                  {this.state.applicationConditions.umidadeMatutino && (
                    <div>
                      <TextField
                        label={res.UMIDADE_INI_MATUTINO}
                        value={this.state.applicationConditions.umidadeInicialMatutino}
                        onChange={(e) => this.handleInputChange('umidadeInicialMatutino', e.target.value)}
                        disabled={!this.state.editData}
                        InputLabelProps={{
                          style: { fontSize: '0.8rem' }
                        }}
                        style={{ padding: '0 1rem 0 0', margin: '0.5rem 0', width: '100%' }}
                      />
                      <TextField
                        label={res.UMIDADE_FIN_MATUTINO}
                        value={this.state.applicationConditions.umidadeFinalMatutino}
                        onChange={(e) => this.handleInputChange('umidadeFinalMatutino', e.target.value)}
                        disabled={!this.state.editData}
                        InputLabelProps={{
                          style: { fontSize: '0.8rem' }
                        }}
                        style={{ padding: '0 1rem 0 0', margin: '0.5rem 0', width: '100%' }}
                      />
                    </div>
                  )}
                </div>
                <div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      type="checkbox"
                      style={{ width: '1.25rem', height: '1.25rem', marginRight: '0.5rem', cursor: this.state.editData ? 'pointer' : null }}
                      checked={this.state.applicationConditions.umidadeVespertino}
                      onChange={() => this.handleCheckboxChange('umidadeVespertino')}
                      disabled={!this.state.editData}
                    />
                    <span style={{ color: this.state.editData ? 'black' : 'rgba(0, 0, 0, 0.38)' }}>{res.VESPERTINO}</span>
                  </div>
                  {this.state.applicationConditions.umidadeVespertino && (
                    <div>
                      <TextField
                        label={res.UMIDADE_INI_VESPERTINO}
                        value={this.state.applicationConditions.umidadeInicialVespertino}
                        onChange={(e) => this.handleInputChange('umidadeInicialVespertino', e.target.value)}
                        disabled={!this.state.editData}
                        InputLabelProps={{
                          style: { fontSize: '0.8rem' }
                        }}
                        style={{ padding: '0 1rem 0 0', margin: '0.5rem 0', width: '100%' }}
                      />
                      <TextField
                        label={res.UMIDADE_FIN_VESPERTINO}
                        value={this.state.applicationConditions.umidadeFinalVespertino}
                        onChange={(e) => this.handleInputChange('umidadeFinalVespertino', e.target.value)}
                        disabled={!this.state.editData}
                        InputLabelProps={{
                          style: { fontSize: '0.8rem' }
                        }}
                        style={{ padding: '0 1rem 0 0', margin: '0.5rem 0', width: '100%' }}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div style={{ width: '25%' }}>
                <FormLabel style={{ display: 'flex', alignItems: 'center', marginTop: '1rem', marginRight: '1rem', color: this.state.editData ? 'black' : 'rgba(0, 0, 0, 0.38)' }}>{res.VENTO_VELOCIDADE}</FormLabel>
                <div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      type="checkbox"
                      style={{ width: '1.25rem', height: '1.25rem', marginRight: '0.5rem', cursor: this.state.editData ? 'pointer' : null }}
                      checked={this.state.applicationConditions.velocidadeMatutino}
                      onChange={() => this.handleCheckboxChange('velocidadeMatutino')}
                      disabled={!this.state.editData}
                    />
                    <span style={{ color: this.state.editData ? 'black' : 'rgba(0, 0, 0, 0.38)' }}>{res.MATUTINO}</span>
                  </div>
                  {this.state.applicationConditions.velocidadeMatutino && (
                    <div>
                      <TextField
                        label={res.VEL_VENTO_INI_MATUTINO}
                        value={this.state.applicationConditions.velocidadeInicialMatutino}
                        onChange={(e) => this.handleInputChange('velocidadeInicialMatutino', e.target.value)}
                        disabled={!this.state.editData}
                        InputLabelProps={{
                          style: { fontSize: '0.8rem' }
                        }}
                        style={{ padding: '0 1rem 0 0', margin: '0.5rem 0', width: '100%' }}
                      />
                      <TextField
                        label={res.VEL_VENTO_FIN_MATUTINO}
                        value={this.state.applicationConditions.velocidadeFinalMatutino}
                        onChange={(e) => this.handleInputChange('velocidadeFinalMatutino', e.target.value)}
                        disabled={!this.state.editData}
                        InputLabelProps={{
                          style: { fontSize: '0.8rem' }
                        }}
                        style={{ padding: '0 1rem 0 0', margin: '0.5rem 0', width: '100%' }}
                      />
                    </div>
                  )}
                </div>
                <div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      type="checkbox"
                      style={{ width: '1.25rem', height: '1.25rem', marginRight: '0.5rem', cursor: this.state.editData ? 'pointer' : null }}
                      checked={this.state.applicationConditions.velocidadeVespertino}
                      onChange={() => this.handleCheckboxChange('velocidadeVespertino')}
                      disabled={!this.state.editData}
                    />
                    <span style={{ color: this.state.editData ? 'black' : 'rgba(0, 0, 0, 0.38)' }}>{res.VESPERTINO}</span>
                  </div>
                  {this.state.applicationConditions.velocidadeVespertino && (
                    <div>
                      <TextField
                        label={res.VEL_VENTO_INI_VESPERTINO}
                        value={this.state.applicationConditions.velocidadeInicialVespertino}
                        onChange={(e) => this.handleInputChange('velocidadeInicialVespertino', e.target.value)}
                        disabled={!this.state.editData}
                        InputLabelProps={{
                          style: { fontSize: '0.8rem' }
                        }}
                        style={{ padding: '0 1rem 0 0', margin: '0.5rem 0', width: '100%' }}
                      />
                      <TextField
                        label={res.VEL_VENTO_FIN_VESPERTINO}
                        value={this.state.applicationConditions.velocidadeFinalVespertino}
                        onChange={(e) => this.handleInputChange('velocidadeFinalVespertino', e.target.value)}
                        disabled={!this.state.editData}
                        InputLabelProps={{
                          style: { fontSize: '0.8rem' }
                        }}
                        style={{ padding: '0 1rem 0 0', margin: '0.5rem 0', width: '100%' }}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div style={{ width: '25%' }}>
                <FormLabel style={{ display: 'flex', alignItems: 'center', marginTop: '1rem', marginRight: '1rem', color: this.state.editData ? 'black' : 'rgba(0, 0, 0, 0.38)' }}>{res.HORARIO_APLICACAO}</FormLabel>
                <div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      type="checkbox"
                      style={{ width: '1.25rem', height: '1.25rem', marginRight: '0.5rem', cursor: this.state.editData ? 'pointer' : null }}
                      checked={this.state.applicationConditions.horarioMatutino}
                      onChange={() => this.handleCheckboxChange('horarioMatutino')}
                      disabled={!this.state.editData}
                    />
                    <span style={{ color: this.state.editData ? 'black' : 'rgba(0, 0, 0, 0.38)' }}>{res.MATUTINO}</span>
                  </div>
                  {this.state.applicationConditions.horarioMatutino && (
                    <div>
                      <TextField
                        type="time"
                        label={res.HORARIO_APLICACAO_INI_MATUTINO}
                        value={this.state.applicationConditions.horarioInicialMatutino}
                        onChange={(e) => this.handleInputChange('horarioInicialMatutino', e.target.value)}
                        disabled={!this.state.editData}
                        InputLabelProps={{
                          shrink: true,
                          style: { fontSize: '0.8rem' }
                        }}
                        inputProps={{
                          step: 300,
                        }}
                        style={{ padding: '0 1rem 0 0', margin: '0.5rem 0', width: '100%' }}
                      />
                      <TextField
                        type="time"
                        label={res.HORARIO_APLICACAO_FIN_MATUTINO}
                        value={this.state.applicationConditions.horarioFinalMatutino}
                        onChange={(e) => this.handleInputChange('horarioFinalMatutino', e.target.value)}
                        disabled={!this.state.editData}
                        InputLabelProps={{
                          shrink: true,
                          style: { fontSize: '0.8rem' }
                        }}
                        inputProps={{
                          step: 300,
                        }}
                        style={{ padding: '0 1rem 0 0', margin: '0.5rem 0', width: '100%' }}
                      />
                    </div>
                  )}
                </div>
                <div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      type="checkbox"
                      style={{ width: '1.25rem', height: '1.25rem', marginRight: '0.5rem', cursor: this.state.editData ? 'pointer' : null }}
                      checked={this.state.applicationConditions.horarioVespertino}
                      onChange={() => this.handleCheckboxChange('horarioVespertino')}
                      disabled={!this.state.editData}
                    />
                    <span style={{ color: this.state.editData ? 'black' : 'rgba(0, 0, 0, 0.38)' }}>{res.VESPERTINO}</span>
                  </div>
                  {this.state.applicationConditions.horarioVespertino && (
                    <div>
                      <TextField
                        type="time"
                        label={res.HORARIO_APLICACAO_INI_VESPERTINO}
                        value={this.state.applicationConditions.horarioInicialVespertino}
                        onChange={(e) => this.handleInputChange('horarioInicialVespertino', e.target.value)}
                        disabled={!this.state.editData}
                        InputLabelProps={{
                          shrink: true,
                          style: { fontSize: '0.8rem' }
                        }}
                        inputProps={{
                          step: 300,
                        }}
                        style={{ padding: '0 1rem 0 0', margin: '0.5rem 0', width: '100%' }}
                      />
                      <TextField
                        type="time"
                        label={res.HORARIO_APLICACAO_FIN_VESPERTINO}
                        value={this.state.applicationConditions.horarioFinalVespertino}
                        onChange={(e) => this.handleInputChange('horarioFinalVespertino', e.target.value)}
                        disabled={!this.state.editData}
                        InputLabelProps={{
                          shrink: true,
                          style: { fontSize: '0.8rem' }
                        }}
                        inputProps={{
                          step: 300,
                        }}
                        style={{ padding: '0 1rem 0 0', margin: '0.5rem 0', width: '100%' }}
                      />
                    </div>
                  )}
                </div>
              </div>

            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <FormControl style={{ marginTop: '1rem', width: '50%', paddingRight: '1rem' }} disabled={!this.state.editData}>
              <InputLabel>{res.EXECUTOR}</InputLabel>
              <Select
                id="executor"
                value={this.state.executor}
                onChange={this.handleTechnicianChange}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
              >
                {this.state.technicians.map((technician) => (
                  <MenuItem key={technician.id} value={technician.name}>
                    {technician.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              id="creaExecutor"
              label={res.CFTA}
              disabled={!this.state.editData}
              style={{ marginTop: '1rem', width: '50%', paddingRight: '1rem' }}
              value={this.state.executor_crea_cpf}
              onChange={this.handleChange('executor_crea_cpf')}
            />
            <TextField
              id="executorEmail"
              label={res.EMAIL}
              disabled={this.state.editData === false}
              style={{ marginTop: '1rem', width: '50%' }}
              value={this.state.executorEmail ? this.state.executorEmail : ''}
              onChange={this.handleChange('executorEmail')}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <FormControl style={{ marginTop: '1rem', width: '50%', paddingRight: '1rem' }} disabled={!this.state.editData}>
              <InputLabel>{res.ENG_AGRONOMO}</InputLabel>
              <Select
                id="agriculturalEngineer"
                value={this.state.agricultural_engineer}
                onChange={this.handleAgronomistChange}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
              >
                {this.state.agronomists.map((agronomist) => (
                  <MenuItem key={agronomist.id} value={agronomist.name}>
                    {agronomist.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              id="engineerCrea"
              label={res.CREA}
              disabled={!this.state.editData}
              style={{ marginTop: '1rem', width: '50%', paddingRight: '1rem' }}
              value={this.state.engineer_crea_cpf}
              onChange={this.handleChange('engineer_crea_cpf')}
            />
            <TextField
              id="engineerEmail"
              label={res.EMAIL}
              disabled={this.state.editData === false}
              style={{ marginTop: '1rem', width: '50%' }}
              value={this.state.engineerEmail ? this.state.engineerEmail : ''}
              onChange={this.handleChange('engineerEmail')}
            />
          </div>
          <div>
            <TextField
              id="latitude"
              label={res.LATITUDE}
              disabled={!this.state.editData}
              style={{ marginTop: '1rem', width: '50%', paddingRight: '1rem' }}
              value={this.state.latitude}
              onChange={this.handleChangeCoordinates('latitude')}
            />
            <TextField
              id="longitude"
              label={res.LONGITUDE}
              disabled={!this.state.editData}
              style={{ marginTop: '1rem', width: '50%' }}
              value={this.state.longitude}
              onChange={this.handleChangeCoordinates('longitude')}
            />
          </div>
          <div>
            <TextField
              id="runwayLatitude"
              label={res.LATITUDE_PISTA}
              disabled={!this.state.editData}
              style={{ marginTop: '1rem', width: '50%', paddingRight: '1rem' }}
              value={this.state.runway_latitude}
              onChange={this.handleChangeCoordinates('runway_latitude')}
            />
            <TextField
              id="runwayLongitude"
              label={res.LONGITUDE_PISTA}
              disabled={!this.state.editData}
              style={{ marginTop: '1rem', width: '50%' }}
              value={this.state.runway_longitude}
              onChange={this.handleChangeCoordinates('runway_longitude')}
            />
          </div>
          <div>
            <div>
              <FormControl style={{ marginTop: '1rem', width: '50%', paddingRight: '1rem' }} disabled={!this.state.editData}>
                <InputLabel id="windDirection-label">{res.DIRECAO_VENTO}</InputLabel>
                <Select
                  labelId="windDirection-label"
                  id="windDirection"
                  value={this.state.wind_direction}
                  onChange={this.handleChange('wind_direction')}
                  style={{ marginTop: this.state.wind_direction ? '0.5rem' : null }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 224,
                        width: 250,
                      },
                    },
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  {directionOptions.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label} {option.icon}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl style={{ marginTop: '1rem', width: '50%', paddingRight: '1rem' }} disabled={!this.state.editData}>
                <InputLabel id="flightDirection-label">{res.DIRECAO_TIRO}</InputLabel>
                <Select
                  labelId="flightDirection-label"
                  id="flightDirection"
                  multiple
                  value={Array.isArray(this.state.flight_direction) ? this.state.flight_direction.filter(value => value !== undefined) : []}
                  onChange={this.handleDirectionChange}
                  renderValue={(selected) => {
                    const selectedArray = Array.isArray(selected) ? selected : [];
                    return (
                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {selectedArray.map((value, index) => {
                          const option = appDirectionOptions.find(opt => opt.value === value);
                          if (!option) {
                            return null;
                          }
                          return (
                            <div key={value} style={{ display: 'flex', alignItems: 'center', marginRight: '8px' }}>
                              <span>{option.label}</span>
                              {option.icon}
                              {index < selectedArray.length - 1 ? ', ' : ''}
                            </div>
                          );
                        })}
                      </div>
                    );
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 224,
                        width: 250,
                      },
                    },
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  {appDirectionOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      <Checkbox checked={this.state.flight_direction.indexOf(option.value) > -1} />
                      <ListItemText primary={option.label} />
                      {option.icon}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div style={{ display: 'flex', border: '1px solid darkgrey', height: '12rem', marginTop: '1rem', justifyContent: 'space-between', opacity: !this.state.editData ? '0.5' : null }}>
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <span style={{ fontWeight: '600', fontSize: '1.25rem', margin: '0.5rem' }}>N</span>
                <span style={{ fontWeight: '600', fontSize: '1.25rem', margin: '0.5rem' }}>S</span>
              </div>
              <div>
                <canvas id="polygon-canvas" style={{ width: '100%', height: '100%' }}></canvas>
              </div>
              <div></div>
            </div>

          </div>
        </div>
      </div >
    );
  }
}

Edit.propTypes = {
  classes: PropTypes.object.isRequired,
};

const TextFields1 = withStyles(styles)(Edit);

const Box = (props) => {
  const { link, res } = props;
  let { id } = useParams();
  return (
    <div className="rowForm">
      <div className="divContent">
        <TextFields1 link={link} id={id} res={res} />
      </div>
    </div>)
}

export default Box;